<div class="flex items-start justify-between">
  <div class="flex flex-col w-full md:space-y-4 pt-4">
    <div class="overflow-auto h-screen pb-24 px-4 md:px-6">
      <div class="bg-white p-5">
        <h1>Customer Information</h1>

        <div class="relative overflow-x-auto mt-6">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">Name</th>
                <th scope="col" class="px-6 py-3">Company</th>
                <th scope="col" class="px-6 py-3">Email</th>
                <th scope="col" class="px-6 py-3">Phone</th>
                <th scope="col" class="px-6 py-3">Address</th>
                <th scope="col" class="px-6 py-3">Address2</th>
                <th scope="col" class="px-6 py-3">City</th>
                <th scope="col" class="px-6 py-3">State</th>
                <th scope="col" class="px-6 py-3">Zip</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" >
                <td class="px-6 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ contactInformation?.name }}</td>
                <td class="px-6 py-3">{{ contactInformation?.company }}</td>
                <td class="px-6 py-3">{{ contactInformation?.email }}</td>
                <td class="px-6 py-3">{{ contactInformation?.phone }}</td>
                <td class="px-6 py-3">{{ contactInformation?.address }}</td>
                <td class="px-6 py-3">{{ contactInformation?.address2 }}</td>
                <td class="px-6 py-3">{{ contactInformation?.city }}</td>
                <td class="px-6 py-3">{{ contactInformation?.state }}</td>
                <td class="px-6 py-3">{{ contactInformation?.zip }}</td>
                <td class="px-6 py-3"> <app-button [routerLink]="[this.accountId]">Edit</app-button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
  </div>
</div>
