import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ApiClient,
  ContactInfoDTO,
  AccountFullInfoDto,
  AccountSettingsDTO,
  UpdateAccountContactRequest,
  UpdateAccountSettingsRequest,
} from '../../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, of, tap } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  templateUrl: './tenant-editor-page.component.html',
  styleUrls: ['./tenant-editor-page.component.scss'],
})

export class TenantEditorPageComponent implements OnInit, OnDestroy {
  accountId?: string;
  account$ = new BehaviorSubject<AccountFullInfoDto | null>(null);

  contactForm: FormGroup;
  settingsForm: FormGroup;
  pricingForm: FormGroup;

  originalContactValue: any;
  originalSettingsValue: any;
  originalPricingValue: any;

  contactHasChanged = false;
  settingsHasChanged = false;
  pricingHasChanged = false;

  constructor(private api: ApiClient, private route: ActivatedRoute, private fb: FormBuilder, private toastService: HotToastService) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      company: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],

      // address
      address1: ['', [Validators.required]],
      address2: [null],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
    });

    this.settingsForm = this.fb.group({
      maxSmsSegments: ['', [Validators.required, Validators.min(0)]],
      maxMmsSegments: ['', [Validators.required, Validators.min(0)]],
      maxRetentionDays: ['', [Validators.required, Validators.min(180), Validators.max(365 * 10)]],
      requestThrottlePerSecond: ['', [Validators.min(0)]],
      managementRequestThrottlePerSecond: ['', [Validators.min(0)]],
    });
    this.pricingForm = this.fb.group({
      minimumMonthlyPrice: [''],

      tiers: this.fb.array([]),
    });

    this.contactForm.valueChanges.subscribe(() => this.detectFormChanges());
    this.settingsForm.valueChanges.subscribe(() => this.detectFormChanges());
    this.pricingForm.valueChanges.subscribe(() => this.detectFormChanges());

    this.route.params.subscribe(({ accountId }) => {
      this.accountId = accountId;

      this.updateFormFromServer();
    });
  }

  get tiersForm(): FormArray {
    return this.pricingForm.get('tiers') as FormArray;
  }

  get tiersFormControls(): FormGroup[] {
    return this.tiersForm.controls as FormGroup[];
  }

  detectFormChanges() {
    this.contactHasChanged = this.hasRefChanged(this.originalContactValue, this.contactForm.value);
    this.settingsHasChanged = this.hasRefChanged(this.originalSettingsValue, this.settingsForm.value);
    this.pricingHasChanged = this.hasRefChanged(this.originalPricingValue, this.pricingForm.value);

    console.log(this.contactHasChanged, this.settingsHasChanged, this.pricingHasChanged);

    console.log(this.contactForm);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.account$.unsubscribe();
  }
  /**
   * Creates an object reference to help with detecting if the form has changed from the original value.
   */
  createRef(object: any) {
    return Object.assign({}, object);
  }

  hasRefChanged(original: any, current: any) {
    return JSON.stringify(original) !== JSON.stringify(current);
  }

  updateContactInfo() {
    this.api
      .updateAccountContact(this.accountId!, {
        contactInfo: {
          contactName: this.contactForm.get('name')!.value,
          company: this.contactForm.get('company')!.value,
          email: this.contactForm.get('email')!.value,
          phone: this.contactForm.get('phone')!.value,
          address: this.contactForm.get('address1')!.value,
          address2: this.contactForm.get('address2')!.value,
          city: this.contactForm.get('city')!.value,
          state: this.contactForm.get('state')!.value,
          zip: this.contactForm.get('zip')!.value,
        },
      })
      .pipe(
        this.toastService.observe({
          loading: 'Saving...',
          success: (s) => {
            return `Saved!`;
          },
          error: (e) => {
            console.log(e);
            return `Failed to save! ${e.error.message}`;
          },
        }),
        tap(() => {
          this.originalContactValue = this.createRef(this.contactForm.value);
          this.contactHasChanged = false;
        }),
        catchError((error) => of(error))
      )
      .subscribe();
  }

  updateSettings() {
    this.api
      .updateAccountSettings(this.accountId!, {
        settings: {
          maxMmsSegments: this.settingsForm.get('maxMmsSegments')!.value,
          maxSmsSegments: this.settingsForm.get('maxSmsSegments')!.value,
          maxRetentionDays: this.settingsForm.get('maxRetentionDays')!.value,
          requestThrottlePerSecond: this.settingsForm.get('requestThrottlePerSecond')!.value,
          managementRequestThrottlePerSecond: this.settingsForm.get('managementRequestThrottlePerSecond')!.value,
        },
      })
      .pipe(
        this.toastService.observe({
          loading: 'Saving...',
          success: (s) => {
            return `Saved!`;
          },
          error: (e) => {
            console.log(e);
            return `Failed to save! ${e.error.message}`;
          },
        }),
        tap(() => {
          this.originalSettingsValue = this.createRef(this.settingsForm.value);
          this.settingsHasChanged = false;
        }),
        catchError((error) => of(error))
      )
      .subscribe();
  }

  private updateFormFromServer() {
    this.api.managementGetAccount(this.accountId!).subscribe((account) => {
      console.log(account);
      this.account$.next(account);

      const latestPricing = account.pricingHistory?.find((p) => p.disabledAt === null);

      this.contactForm.setValue({
        name: account.contactInfo?.contactName,
        company: account.contactInfo?.company,
        email: account.contactInfo?.email,
        phone: account.contactInfo?.phone,

        address1: account.contactInfo?.address,
        address2: account.contactInfo?.address2 ?? '',
        city: account.contactInfo?.city,
        state: account.contactInfo?.state,
        zip: account.contactInfo?.zip,
      });
      this.originalContactValue = this.createRef(this.contactForm.value);

      this.settingsForm.setValue({
        maxSmsSegments: account.settings?.maxSmsSegments,
        maxMmsSegments: account.settings?.maxMmsSegments,
        maxRetentionDays: account.settings?.maxRetentionDays,
        requestThrottlePerSecond: account.settings?.requestThrottlePerSecond,
        managementRequestThrottlePerSecond: account.settings?.managementRequestThrottlePerSecond,
      });
      this.originalSettingsValue = this.createRef(this.settingsForm.value);

      this.pricingForm.setValue({
        minimumMonthlyPrice: latestPricing?.minimumMonthlyPrice ?? 0,
        tiers: [],
      });

      // sort the tiers by minMmsSegments and minSmsSegments

      latestPricing?.tiers
        ?.sort((a, b) => {
          if (a.minMmsSegments === b.minMmsSegments) {
            return a.minSmsSegments! - b.minSmsSegments!;
          } else {
            return a.minMmsSegments! - b.minMmsSegments!;
          }
        })
        .forEach((tier) => {
          this.tiersForm.push(
            this.fb.group({
              minMmsSegments: [tier.minMmsSegments, [Validators.required, Validators.min(1)]],
              minSmsSegments: [tier.minSmsSegments, [Validators.required, Validators.min(1)]],
              mmsSegmentPrice: [tier.mmsSegmentPrice, [Validators.required, Validators.min(0)]],
              smsSegmentPrice: [tier.smsSegmentPrice, [Validators.required, Validators.min(0)]],
            })
          );
        });

      this.originalPricingValue = this.createRef(this.pricingForm.value);

      this.detectFormChanges();
    });

    // this.toastService.success('Successfully loaded tenant info.');
  }
}
