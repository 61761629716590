import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticatorService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    // Only inject the JWT if we're signed in.
    if (this.auth.user.getSignInUserSession()) {
      // Only specify the JWT token if the URL is going to localhost or herm.es
      if (
        request.url.includes('localhost') ||
        request.url.includes('herm.es')
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.auth.user
              .getSignInUserSession()
              ?.getAccessToken()
              .getJwtToken()}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
