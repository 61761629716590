import { Component, OnInit } from '@angular/core';
import { AccountDTO, ApiClient } from '../../api/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  account?: AccountDTO;
  accountId?: string;
  constructor(private api: ApiClient, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(({ accountId }) => {
      this.accountId = accountId;
      this.api.getAccount(accountId).subscribe((account) => {
        this.account = account;
      });
    });
  }
}
