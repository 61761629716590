import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Amplify } from 'aws-amplify';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { LeftNavComponent } from './components/left-nav/left-nav.component';
import { IndexComponent } from './pages/index/index.component';
import { ClickerPageComponent } from './pages/clicker-page/clicker-page.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AlertComponent } from './components/alert/alert.component';
import { BadgeComponent } from './components/badge/badge.component';
import { TenantManagementPageComponent } from './pages/tenant-management-page/tenant-management-page.component';
import { API_BASE_URL, ApiClient } from './api/api.service';
import { ModalComponent } from './components/modal/modal.component';
import { TenantEditorPageComponent } from './pages/tenant-management-page/tenant-editor-page/tenant-editor-page.component';
import { InputComponent } from './components/forms/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from '@ngneat/input-mask';
import { PricingTierCalculatorPageComponent } from './pages/pricing-tier-calculator-page/pricing-tier-calculator-page.component';
import { PricingTierCalculatorComponent } from './components/pricing-tier-calculator/pricing-tier-calculator.component';
import { ButtonComponent } from './components/button/button.component';
import { HotToastModule } from '@ngneat/hot-toast';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { AccountPageComponent } from './pages/account-page/account-page.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AccountAnalyticsPageComponent } from './pages/account-analytics-page/account-analytics-page.component';
import { AccountServicesPageComponent } from './pages/account-services-page/account-services-page.component';
import { AccountServiceManagementPageComponent } from './pages/account-service-management-page/account-service-management-page.component';
import { AccountMessagesPageComponent } from './pages/account-messages-page/account-messages-page-component';
import { PhonePreviewComponent } from './components/phone-preview/phone-preview.component';
import { MessageTemplateEditorPageComponent } from './pages/message-template-editor-page/message-template-editor-page.component';
import { VendorIntegrationManagementPageComponent } from './pages/account-service-management-page/vendor-integration-management-page/vendor-integration-management-page.component';
import { AccountCustomerInfoComponent } from './pages/account-customer-info-page/account-customer-info-component';
import { environment } from '../environments/environment';
import { AccountContactManagementComponent } from './pages/account-contact-management-page/account-contact-management-component';

Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_QoKmXCwfR',
    userPoolWebClientId: 'n0lja5pqkm7tgn5plslj6mko9',

    oauth: {
      domain: 'https://smshermesdev.auth.us-east-2.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.origin,
      redirectSignOut: window.origin,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

@NgModule({
  declarations: [
    AppComponent,
    LeftNavComponent,
    IndexComponent,
    ClickerPageComponent,
    AlertComponent,
    BadgeComponent,
    TenantManagementPageComponent,
    ModalComponent,
    TenantEditorPageComponent,
    InputComponent,
    PricingTierCalculatorPageComponent,
    PricingTierCalculatorComponent,
    ButtonComponent,
    UnauthorizedPageComponent,
    TopNavComponent,
    AccountPageComponent,
    AccountAnalyticsPageComponent,
    AccountServicesPageComponent,
    AccountServiceManagementPageComponent,
    AccountMessagesPageComponent,
    PhonePreviewComponent,
    MessageTemplateEditorPageComponent,
    VendorIntegrationManagementPageComponent,
    AccountCustomerInfoComponent,
    AccountContactManagementComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    AmplifyAuthenticatorModule,
    InputMaskModule,
    HotToastModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    FormsModule,
  ],

  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiUrl,
    },
    ApiClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
