<div class="flex items-start justify-between">
    <div class="flex flex-col w-full md:space-y-4 pt-4">
      <div class="pb-24 px-4 md:px-6">
        <div class="bg-white p-5">
          <h1>Contact Information</h1>
          <p>Opt outs</p>
  
          <div class="relative overflow-auto h-[50vh] mt-6">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" *ngIf="phoneOptOuts$ | async">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">id</th>
                  <th scope="col" class="px-6 py-3">Phone</th>
                  <th scope="col" class="px-6 py-3">Created At</th>
                  <th scope="col" class="px-6 py-3">Deleated At</th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" *ngFor="let t of phoneOptOuts$ | async">
                  <td class="px-6 py-4">{{ t.id }}</td>
                  <td class="px-6 py-4">{{ t.phone ?? "-" }}</td>
                  <td class="px-6 py-4">{{ t.createdAt | date: "medium":"UTC" }}</td>
                  <td class="px-6 py-4">{{ t.deletedAt | date: "medium":"UTC" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full md:space-y-4 pt-4">
        <div class="pb-24 px-4 md:px-6">
          <div class="bg-white p-5">
     
        <h1>Phone Opt-Out</h1>
        <p>Remove phone to opt out </p>
      
        <div class="flex-col p-2">
          <app-input
              inputType="tel"
              maxlength="20"
              label="Phone Number"
              [ngModel]="inPhoneNumber"
              (ngModelChange)="phoneOptOutChanged($event)"
          ></app-input>
        </div>
    
        <div class="flex-col p-2">
            <app-button type="button" (buttonClick)="addPhoneOptOut()" buttonText="Add"></app-button>
            <app-button type="button" (buttonClick)="removePhoneOptOut()" buttonText="Remove"></app-button>
        </div>
    </div>
</div>
