import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, ms);
  });
}
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public authenticator: AuthenticatorService, private router: Router) {}

  async canActivateAsync(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // This is a stupid hack because the Amplify auth stuff is very slow to start. They have to run through some state machine.
    // So this only really applies on "startup" authentication guards. We just have to wait for the "route" to get out of "setup", at which point we're in a usable state.
    // while (this.authenticator.route === 'setup') {
    //   await wait(1);
    // }

    if (this.authenticator.route !== 'authenticated') {
      console.log('Not authenticated');
      return false;
    }

    const currentUserGroups = this.authenticator.user.getSignInUserSession()?.getIdToken().payload['cognito:groups'] as string[];

    // Admins can access all pages
    if (currentUserGroups?.includes('admin')) {
      console.log('Admin');
      return true;
    }

    const { roles } = route.data;
    if (!!roles) {
      const ret = currentUserGroups?.some((group: string) => roles.includes(group)) ?? false;

      if (ret) {
        console.log('User has access');
        return true;
      } else {
        console.log('User does not have access');
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateAsync(route, state);
  }
}
