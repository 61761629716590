import { Component, OnInit } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  constructor(public authenticator: AuthenticatorService) {}

  ngOnInit(): void {}

  get role() {
    const roles = this.authenticator.user.getSignInUserSession()?.getAccessToken()?.payload?.['cognito:groups'] as string[];

    if (roles.includes('admin')) return 'Administrator';

    if (roles.includes('management')) return 'Management';

    if (roles.includes('clicker')) return 'Clicker';

    return 'User';
  }
}
