import { AfterContentInit, AfterViewInit, Component, forwardRef, Injector, Input, OnInit, Optional, Self, Type } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => InputComponent),
  //     multi: true,
  //   },
  // ],
})
export class InputComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @Input() label?: string;
  @Input() inputType: string = 'text';
  @Input() name?: string;
  @Input() placeholder: string = '';
  @Input() inputMask?: any;
  @Input() textSize: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() inputClass?: string;

  value: string | null = null;
  disabled: boolean = false;
  onChange: any = () => {};
  onTouched: any = () => {};
  control?: AbstractControl;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      ngControl.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    const control = this.ngControl && this.ngControl.control;
    if (control) {
      // FormControl should be available here
      this.control = control;
    }
  }

  ngOnInit(): void {}

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  valueChanged($event: any) {
    this.value = $event.target['value'];
    this.onChange(this.value);
  }

  validationMessage() {
    if (this.control && this.control.errors) {
      const { email, max, maxDate, maxlength, min, minDate, minlength, pattern, required } = this.control.errors;

      if (required) {
        return 'This field is required';
      }

      if (minlength) {
        const requiredLength = minlength.requiredLength;
        return `This field requires at least ${requiredLength} characters`;
      }

      if (maxlength) {
        const requiredLength = maxlength.requiredLength;
        return `This field requires at most ${requiredLength} characters`;
      }

      if (pattern) {
        return 'This field contains invalid characters';
      }

      if (email) {
        return 'This field requires a valid email address';
      }

      if (min) {
        return `This field requires a value greater than or equal to ${min.min}`;
      }

      if (max) {
        return `This field requires a value less than or equal to ${max.max}`;
      }

      if (minDate) {
        return `This field requires a date greater than or equal to ${minDate.minDate}`;
      }

      if (maxDate) {
        return `This field requires a date less than or equal to ${maxDate.maxDate}`;
      }

      return 'There is something wrong with this field!';
    }

    return null;
  }
}
