<h3 class="pb-3">{{action}} template {{ template?.name }}</h3>

<!-- 2 column "editor" -->
<!-- Fields on the left for "parameters" and main textarea for the "template" -->

<!-- Right hand side will have the phone preview, with a dynamic preview of the template -->

<div class="grid grid-cols-3">
  <div class="col-span-2">
    <form [formGroup]="editForm">
      <app-input formControlName="name" label="Name" inputType="text" required></app-input>
      <app-input formControlName="template" inputClass="font-mono" textSize="md" label="Template" inputType="textarea" required></app-input>
      <p class="text-sm text-gray-600 pb-2 border-b border-b-gray-200">
        Templates are written in the <a target="_blank" href="https://shopify.github.io/liquid/">Liquid</a> templating language, and implemented/extended by the
        <a target="_blank" href="https://github.com/sebastienros/fluid">Fluid Library</a>.
      </p>

      <div>
        <h5>Parameters</h5>
        <!-- For each of the controls in the editForm.params control group, display a 'key' input, and a 'value' input side by side -->
        <ng-container formArrayName="params">
          <ng-container *ngFor="let param of paramsControls; let i = index">
            <div [formGroup]="param" class="flex flex-row align-middle items-center w-full gap-2">
              <app-input formControlName="key" inputType="text" required></app-input>
              <app-input formControlName="value" inputType="text" required></app-input>
              <app-button buttonType="button" variant="outline" color="red" size="sm" (click)="paramsControl.removeAt(i)">Remove</app-button>
            </div>
          </ng-container>
        </ng-container>
        <app-button buttonType="button" variant="outline" color="green" size="sm" (click)="addParameter('', '')">Add Parameter</app-button>
      </div>

      <app-button *ngIf="action == 'Editing'" buttonType="submit" variant="outline" size="lg" (click)="save()">Save</app-button>
      <app-button *ngIf="action == 'Adding'" buttonType="submit" variant="outline" size="lg" (click)="add()">Add</app-button>
    </form>
  </div>
  <div>
    <app-phone-preview #phonePreview></app-phone-preview>

    <ng-container *ngIf="templateError">
      <div class="text-red-500 text-center">
        <h3>Error</h3>
        <p>{{ templateError }}</p>
      </div>
    </ng-container>
  </div>
</div>
