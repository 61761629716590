import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, Observable, Subscription, tap, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiClient } from 'src/app/api/api.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

interface ClickerQueueMessage {
  messageId: string;
  toPhone: string;
  messageText: string;
  attachmentUrl: string;
  vendorName: string;
  receipt: string;
}
interface ClickerQueueResponse {
  queue: ClickerQueueMessage[];
}

@Component({
  selector: 'app-clicker-page',
  templateUrl: './clicker-page.component.html',
  styleUrls: ['./clicker-page.component.scss'],
})

export class ClickerPageComponent implements OnInit, OnDestroy {
  pendingConfirmations: ClickerQueueMessage[] = [];
  availableConfirmations: ClickerQueueMessage[] = [];

  // This is the number of messages to keep available for the clicker to click
  keepAvailableMessages = 1;
  clicks = 0;
  clicksHour = 0;
  frequency = 0;
  frequencyMinute = 0;
  start?: Date;

  counters: number[] = [];
  currentCounter = 0;
  last = 0;
  timedout = 0;


  //confirmationSendTimer$: Subscription;
  confirmationRequestTimer$: Subscription;
  //autoClickTimer$: Subscription;
  fixItNow$: Subscription;
  autoClickMessages: boolean = false;
  sending: boolean = false;

  constructor(private api: ApiClient, public authenticator: AuthenticatorService) {
    for (let i = 0; i < 10; i++) {
      this.counters.push(0);
    }

    // NOTE: This is a seriously crude version of the "clicks per second" counter.
    // In reality, this should be a better circular buffer implementation. This is just enough to get a rough idea for now.
    // In the future, I'd like to do a proper implementation.
  /*  this.timer$ = timer(0, 1000)
      .pipe(
        tap((i) => {
          // Roll the counter over after it reaches the last bucket
          this.currentCounter = (this.currentCounter + 1) % this.counters.length;

          this.counters[this.currentCounter] = 0;

          // console.log(this.currentCounter);
          this.updateClicksPerHour();
        })
      )
      .subscribe();

      this.timer$ = timer(0, 36000)
      .pipe(
        tap((i) => {
          this.clicksHour = 0;
        })
      )
      .subscribe();
*/

   /* this.confirmationSendTimer$ = timer(0, 500)
      .pipe(
        tap((i) => {
          const tmp = [...this.pendingConfirmations];
          this.pendingConfirmations = [];

          if (tmp.length > 0) {
            this.sending = true;
            // Send these off to the server as "confirmed" clicks.
            this.api
              .clickerConfirmMessages({
                confirmations: tmp.map((o) => {
                  return {
                    messageId: o.messageId,
                    receipt: o.receipt,
                  };
                }),
              })
              .pipe(
                
                catchError((err: Error, caught: Observable<Object>) => {
                  console.error(err);
                  this.pendingConfirmations.push(...tmp);
                  this.sending = false;
                  return caught;
                })
                
              )
              .subscribe();
              this.sending = false;
          }
        })
      )
      .subscribe();*/


    this.fixItNow$ = timer(0,300)
      .pipe(
        tap((i) => {
         /* if (this.autoClickMessages && this.availableConfirmations.length >= this.keepAvailableMessages) {
            this.pendingConfirmations = this.availableConfirmations;
            this.availableConfirmations = [];}*/
            if(this.autoClickMessages && this.availableConfirmations.length > 0 && this.sending == false)
            {
              this.timedout = 0;
              this.sending = true;
              this.pendingConfirmations.push(this.availableConfirmations.shift()!);
              return;
            }

            if(this.sending == true)
              this.timedout++;

            if(this.timedout > 30 && this.autoClickMessages && this.availableConfirmations.length > 0)
            {
              this.timedout = 0;
              this.sending = true;
              this.pendingConfirmations.push(this.availableConfirmations.shift()!);
            }

            const tmp = [...this.pendingConfirmations];
            this.pendingConfirmations = [];
  
            if (tmp.length > 0) {
              this.sending = true;
              // Send these off to the server as "confirmed" clicks.
              this.api
                .clickerConfirmMessages({
                  confirmations: tmp.map((o) => {
                    return {
                      messageId: o.messageId,
                      receipt: o.receipt,
                    };
                  }),
                })
                .pipe(
                  
                  catchError((err: Error, caught: Observable<Object>) => {
                    console.error(err);
                    this.pendingConfirmations.push(...tmp);
                    this.sending = false;
                    return caught;
                  })
                  
                )
                .subscribe();
                this.sending = false;
            }

        })
      )
      .subscribe();
    

    /*this.autoClickTimer$ = timer(0, 300)
      .pipe(
        tap((i) => {*/
         /* if (this.autoClickMessages && this.availableConfirmations.length >= this.keepAvailableMessages) {
            this.pendingConfirmations = this.availableConfirmations;
            this.availableConfirmations = [];}*/
            /*if(this.autoClickMessages && this.availableConfirmations.length > 0 && this.sending == false)
            {
              this.timedout = 0;
              this.confirmedClick();
              return;
            }

            if(this.sending == true)
              this.timedout++;

            if(this.timedout > 30 && this.autoClickMessages && this.availableConfirmations.length > 0)
            {
              this.timedout = 0;
              this.confirmedClick();
            }
        })
      )
      .subscribe();*/
    //
    this.confirmationRequestTimer$ = timer(0, 800)
      .pipe(
        // map(() => this.availableConfirmations.length < 25),
        tap(() => {
          // If there are less than 25 confirmations waiting for the clicker, go ahead and request more.
          // This will make sure we always have some ready for the clicker to .... click
          if (this.availableConfirmations.length < this.keepAvailableMessages) {
            this.api.clickerDequeueMessages().subscribe((data: any) => {
              this.availableConfirmations.push(...data.queue);
            });
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.fixItNow$.unsubscribe();
    this.confirmationRequestTimer$.unsubscribe();
  }

  ngOnInit(): void {}

  beforeInput(event: any) {
    // Essentially, this stops all keyboard input
    // Not sure if we need this legally. (It would prevent the issue with keyboards that automatically repeat input)
    event.stopPropagation();
    event.preventDefault();
  }

  // When the button is clicked, track the total number of clicks, as well as calculate "clicks per second"
  confirmedClick() {
    this.counters[this.currentCounter]++;

    this.clicks++;
    this.clicksHour++;

    // Basically, take out the available confirmation, and put it in the pending confirmation. ... magic!
    this.sending = true;
    this.pendingConfirmations.push(this.availableConfirmations.shift()!);
    
   
    this.updateClicksPerHour();
  }

  

  updateClicksPerHour() {
    // Average all the counters 
    /*let total = 0;
    for (let i = 0; i < this.counters.length; i++) {
      // Skip the current counter, since it's not yet complete
      if (i === this.currentCounter) {
        continue;
      }
      total += this.counters[i];
    }
    this.frequency = total / (this.counters.length - 1) * 60;
    console.log(this.counters);*/

    //no idea what jim is doing, lets make it simple
    this.frequency = this.clicksHour;
  }

  toggleAutoClicker() {
    this.autoClickMessages = !this.autoClickMessages; 
  }
}
