<div class="py-5">
  <h2>Vendor Integration Management</h2>
  <div>
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3 text-left">Vendor</th>
          <th scope="col" class="px-6 py-3 text-left">Service ID</th>
          <th scope="col" class="px-6 py-3 text-left">Vendor Account ID</th>
          <th scope="col" class="px-6 py-3 text-left">Created</th>
          <th scope="col" class="px-6 py-3 text-left">Updated</th>
          <th scope="col" class="px-6 py-3 text-left">Enable/Disable</th> 
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
          <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ integration.vendor?.name }}</td>
          <td class="text-left">
            <ng-container *ngIf="integration.vendor?.name == 'Twilio'; else defaultDisplay">
              <a
                target="_blank"
                href="https://console.twilio.com/us1/service/sms/{{
                  integration.vendorServiceId
                }}/messaging-service-properties?frameUrl=%2Fconsole%2Fsms%2Fservices%2F{{ integration.vendorServiceId }}%2Fproperties%3Fx-target-region%3Dus1"
              >
                {{ integration.vendorServiceId }}
              </a>
            </ng-container>
            <ng-template #defaultDisplay>
              {{ integration.vendorServiceId }}
            </ng-template>
          </td>
          <td class="text-left">{{ integration.vendorAccountId }}</td>
          <td class="text-left">{{ integration.createdAt | date: "yyyy-MM-dd hh:mm:ss":"UTC" }}</td>
          <td class="text-left">{{ integration.updatedAt | date: "yyyy-MM-dd hh:mm:ss":"UTC" }}</td>
          <td class="text-left">
            <input class="p-2" type="checkbox" (ngModelChange)="toggleEnable($event)" [(ngModel)]="integrationEnabled" />
            <label class="p-2" for="Enabled">{{enabledText}}</label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <br />
  <hr>
  <br />
  <h2>Provisioned Phones</h2>
  <div>
    <table class="w-full text-sm text-center text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3 text-center">Number</th>
          <th scope="col" class="px-6 py-3 text-center">Number Id</th>
          <th scope="col" class="px-6 py-3 text-center">Vendor</th>
          <th scope="col" class="px-6 py-3 text-center">Created</th>
          <th scope="col" class="px-6 py-3 text-center">Updated</th>
          <th scope="col" class="px-6 py-3 text-center">Active</th>
          <th scope="col" class="px-6 py-3 text-center">Release</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" *ngFor="let phone of phones$ | async;let i = index">
          <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ phone.phoneNumber }}</td>
          <td class="text-center">{{ phone.vendorPhoneNumberId }}</td>
          <td class="text-center">{{ phone.vendor?.name }}</td>
          <td class="text-center">{{ phone.createdAt | date: "yyyy-MM-dd hh:mm:ss":"UTC" }}</td>
          <td class="text-center">{{ phone.updatedAt | date: "yyyy-MM-dd hh:mm:ss":"UTC" }}</td>
          <td class="text-center">{{ ConvertStatus(phone) }}</td>
          <td class="text-center px-6 pt-4 pb-2">
            <app-button variant="outline" (buttonClick)="releasePhone(phone,i)">Release</app-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="my-7 w-full flex justify-end">
      <app-button type="button" (buttonClick)="provisionPhoneModal()" buttonText="Provision New Phone"></app-button>
    </div>
  </div>

  <app-modal #phoneProvision>
    <h1>Provision Phone</h1>
    <p>Use your account default or Choose area code you wish to use </p>
  
    <div class="flex-col p-2 w-20">
      <app-input
          inputType="text"
          maxlength="3"
          label="Area Code"
          [ngModel]="areaCode"
          (ngModelChange)="areaCodeChanged($event)"
      ></app-input>
    </div>

    <div class="flex-col p-2">
        <app-button type="button" (buttonClick)="lookupPhoneByAreaCode()" buttonText="Provision New Phone"></app-button>
    </div>
    
  </app-modal>
</div>

