import { Component } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'internal-website';
  totpFields = {
    setupTOTP: {
      QR: {
        totpIssuer: 'Hermes',
        totpUsername: this.authenticator.user?.getUsername(),
      },
    },
  };

  constructor(public authenticator: AuthenticatorService, private router: Router, private titleService: Title) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';

          while (route!.firstChild) {
            if (route.snapshot.data['title']) {
              routeTitle += ' - ' + route.snapshot.data['title'];
            }
            route = route.firstChild;
          }

          if (route.snapshot.data['title']) {
            routeTitle += ' - ' + route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`Hermes${title}`);
        }
      });
  }
}
