import { ChangeDetectorRef, Component, Inject, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiClient, GetVendorIntegrationRequest, MessageServiceDTO, PhoneNumberDTO, VendorIntegration, VendorIntegrationDTO } from '../../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, finalize, Observable, of, Subject, switchMap, tap, throwError } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { ModalComponent } from '../../../../app/components/modal/modal.component';
import { DOCUMENT } from '@angular/common';

@Component({
  templateUrl: './vendor-integration-management-page.component.html',
  styleUrls: ['./vendor-integration-management-page.component.scss'],
})

export class VendorIntegrationManagementPageComponent implements OnInit, OnDestroy {
  service: MessageServiceDTO = {};
  integration: VendorIntegrationDTO = {};
  integrationEnabled: boolean | undefined = false;
  @ViewChild('phoneProvision') phoneProvision?: ModalComponent;
  areaCode?: string;
 

  accountId?: string;
  public serviceId?: string;
  public vendorIntegrationId?: string;
  enabledText: string = 'Enabled';

 
  public phones: PhoneNumberDTO[] = [];
  phone: PhoneNumberDTO = {};
  public phones$ = new BehaviorSubject<PhoneNumberDTO[]>([]);

  updatedAt: Date = new Date();
  phoneAccount?: string;


  constructor(private api: ApiClient, private route: ActivatedRoute, private changeDetector: ChangeDetectorRef, private toast: HotToastService) {}
  // /accounts/:accountId/services/:serviceId - accountId and serviceId are required
  // Get the full service info from the API

  ngOnInit(): void {
    this.route.params.subscribe(({ accountId, serviceId, vendorIntegrationId }) => {
      this.accountId = accountId;
      this.serviceId = serviceId;
      this.vendorIntegrationId = vendorIntegrationId;

      this.api.listContactsInfo(accountId).subscribe((accountDTO) => {
        this.phoneAccount = accountDTO.contactInformation?.phone;
        this.areaCode = this.phoneAccount?.replace('+1','').replace('+','');
        if(this.areaCode != "800" && this.areaCode != "888" && this.areaCode != "877" && this.areaCode !=  "866" && this.areaCode !=  "855" && this.areaCode != "844" && this.areaCode != "833")
          this.areaCode = this.areaCode?.substring(0,3);
        else
          this.areaCode = "";
      });

    });

    this.api.getVendorIntegration({ vendorIntegrationId: this.vendorIntegrationId }).subscribe((integration) => {
      this.integration = integration;
      this.integrationEnabled = integration.enabled;
      this.enabledText = this.integrationEnabled == true ? 'Enabled' : 'Disabled';
    });

    this.api.listPhoneForService({ messageServiceId: this.serviceId }).subscribe(
      (phones) => {
        this.phones = phones;
        this.phones$.next(this.phones);
        this.changeDetector.detectChanges();
      });
    }
  

  toggleEnable($event: any): void {
    this.api.updateVendorIntegrationEnabled({ vendorIntegrationId: this.integration.id, enabled: $event }).subscribe(() => {
      this.integration.enabled = $event;
      this.integrationEnabled = this.integration.enabled;
      this.enabledText = this.integrationEnabled == true ? 'Enabled' : 'Disabled';
    });
  }

  provisionPhoneModal() {
    this.phoneProvision?.show();
  }

  lookupPhoneByAreaCode() {
    this.api.provisionPhone(
      {
        accountId: this.accountId,
        messageServiceId: this.integration.messageServiceId,
        updatedAt: new Date(),
        vendor: this.integration.vendor,
        vendorIntegration: this.integration,
        areaCode: this.areaCode
      })
      .pipe(
        this.toast.observe({
          loading: 'Saving...',
          success: (s) => {
            return "added"
          },
          error: (e) => {
             return `Failed to add! ${e.message}`;
          },
        }),
        tap(() => {
        }),
        catchError((err) => {
          this.toast.error(err.message);
          return throwError(err);
        })
      )
      .subscribe((apiPhone) => {
        if(apiPhone.phoneNumber != null && apiPhone.phoneNumber != "")
        { 
          this.phones?.push(apiPhone);
          this.phones$.next(this.phones);
          this.phoneProvision?.hide();
          this.changeDetector.detectChanges();
        }
        else
        {
          //TODO test this
          this.toast.error(apiPhone.errorMessage);
        }
      });
  }

  areaCodeChanged(event$: any) {
    //TODO validate all numbers
    this.areaCode = event$;
  }

  releasePhone(event$: PhoneNumberDTO, idx: any) {
    this.api.updatePhoneNumber(
      {
        id: event$.id,
        accountId: this.accountId,
        messageServiceId: this.integration.messageServiceId,
        vendor: this.integration.vendor,
        vendorIntegration: this.integration,
        phoneNumber: event$.phoneNumber,
        vendorIntegrationId: event$.vendorIntegration?.id,
        vendorPhoneNumberId: event$.phoneNumber,
      }).subscribe((releasedPhone) => {
        if(idx != null)
        {
          this.phones[idx] = releasedPhone;
        }
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.phones$.unsubscribe();
  }

  ConvertStatus(event$: PhoneNumberDTO): string {
    var stat = '';

    switch(event$.status)
    {
      case 0:
        stat = 'Active'
        break;
      case 1:
        stat = 'Released'
        break;
      case 2: 
        stat = 'Waiting'
        break;
      case 3:
        stat = 'InProgress'
        break;
      case 4:
        stat = 'Failed'
        break;
    }

    return stat;
  }
}



