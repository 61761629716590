import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() buttonClass?: string = '';
  @Input() buttonType?: string = 'button';
  @Input() disabled: boolean = false;
  @Input() buttonText?: string;

  @Input() variant: 'default' | 'outline' = 'default';
  @Input() color: 'default' | 'alt' | 'dark' | 'light' | 'green' | 'red' | 'yellow' | 'purple' = 'default';
  @Input() pill: boolean = false;

  @Input() size?: 'xs' | 'sm' | 'lg' | 'xl';

  @Input() gradient?: 'default' | 'green' | 'cyan' | 'teal' | 'lime' | 'red' | 'pink' | 'purple';
  @Input() gradientDuotone?: 'purple-to-blue' | 'cyan-to-blue' | 'green-to-blue' | 'purple-to-pink' | 'pink-to-orange' | 'teal-to-lime' | 'red-to-yellow';
  // @Input() gradientOutline?: 'purple-to-blue' | 'cyan-to-blue' | 'green-to-blue' | 'purple-to-pink' | 'pink-to-orange' | 'teal-to-lime' | 'red-to-yellow';

  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @ContentChild('wrapper') wrapper?: ElementRef;
  constructor() {}

  ngOnInit(): void {}

  getCssClass() {
    const pillClass = this.pill ? 'btn-pill ' : '';
    const sizeClass = this.size ? `btn-${this.size} ` : '';
    // if (this.gradientOutline) {
    //   return `btn btn-gradient-duo-outline-${this.gradientOutline} ${pillClass} ${sizeClass} ${this.buttonClass}`;
    // }
    if (this.gradientDuotone) {
      return `btn btn-gradient-duo-${this.gradientDuotone} ${pillClass} ${sizeClass} ${this.buttonClass}`;
    }
    if (this.gradient) {
      return `btn btn-gradient-${this.gradient} ${pillClass} ${sizeClass} ${this.buttonClass}`;
    }
    if (this.variant == 'default') {
      return `btn btn-${this.color} ${pillClass} ${sizeClass} ${this.buttonClass}`;
    }
    if (this.variant == 'outline') {
      return `btn btn-outline-${this.color} ${pillClass} ${sizeClass} ${this.buttonClass}`;
    }

    return `${pillClass} ${sizeClass} ${this.buttonClass}`;
  }
}
