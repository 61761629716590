<nav class="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-800 flex flex-col justify-center items-center gap-3 border-b border-gray-500">
  <div class="text-center">
    <h1 class="text-2xl pb-0">Account: {{ account?.name }}</h1>
    <p class="text-xs text-gray-500 m-0">{{ accountId }}</p>
  </div>

  <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium justify-center">
    <li><a [routerLink]="['analytics']" routerLinkActive="nav-item-active" class="nav-item text-xs">Analytics</a></li>
    <li><a [routerLink]="['reports']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">Reports</a></li>
    <li><a [routerLink]="['messages']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">Messages</a></li>
    <li>|</li>
    <li><a [routerLink]="['services']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">Services</a></li>
    <!-- has to have a message service id-->
    <li><a [routerLink]="['template']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">Templates</a></li>
    <!-- has to have a message service id-->
    <li>|</li>
    <li><a [routerLink]="['contacts']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">Contact Management</a></li>
    <li><a [routerLink]="['users']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">User Management</a></li>
    <li>|</li>
    <li><a [routerLink]="['customer']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">Customer Info</a></li>
    <li><a [routerLink]="['billing']" routerLinkActive="nav-item-active" href="#" class="nav-item text-xs">Billing</a></li>
  </ul>
</nav>

<div class="container mx-auto px-4 py-8">
  <router-outlet></router-outlet>
</div>
