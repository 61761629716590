<!-- Main modal -->
<div
  #modal
  tabindex="-1"
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
  (click)="backdropClicked($event)"
>
  <div class="relative p-4 w-full {{ sizeClass }} h-full md:h-auto">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <div class="flex flex-row flex-row-reverse justify-between border-b border-gray-100 mb-4">
        <div class="flex justify-end p-2">
          <button
            (click)="toggle()"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <h4 class="p-4">{{ title }}</h4>
      </div>

      <div class="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
