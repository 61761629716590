import { ChangeDetectorRef, Component, OnInit, AfterViewInit, Pipe, PipeTransform, ViewChild, OnDestroy, Inject, Optional } from '@angular/core';
import { AccountDTO, ApiClient, ContactInformation } from '../../api/api.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ContactMethod } from '@aws-amplify/ui';

@Component({
  selector: 'app-customer-info',
  templateUrl: './account-customer-info-component.html',
  styleUrls: ['./account-customer-info-component.scss'],
})

export class AccountCustomerInfoComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private api: ApiClient, private route: ActivatedRoute, private changeDetector: ChangeDetectorRef){}

  public contactInformation?: ContactInformation = {};
  accountId?: string;

  ngOnInit(): void {
    this.route.params.subscribe(({ accountId }) => {
        this.accountId = accountId;
        this.api.listContactsInfo(accountId).subscribe((accountDTO) => {
          this.contactInformation = accountDTO.contactInformation;
      });
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

}


