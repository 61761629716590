<ng-container *ngIf="authenticator.route === 'authenticated'">
  <app-top-nav></app-top-nav>

  <main class="bg-gray-50 dark:bg-gray-800 h-screen relative font-sans font-normal leading-relaxed text-gray-800 text-sm">
    <router-outlet></router-outlet>
  </main>

  <!--  <h1>Welcome {{ authenticator.user?.attributes?.email }}!</h1>-->

  <!--  <button-->
  <!--    (click)="authenticator.signOut()"-->
  <!--    class="px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"-->
  <!--  >-->
  <!--    Sign Out-->
  <!--  </button>-->

  <!--  <code>-->
  <!--    <pre>{{ authenticator.user | json }}</pre>-->
  <!--  </code>-->
</ng-container>

<ng-container *ngIf="authenticator.route !== 'authenticated'">
  <amplify-authenticator [formFields]="totpFields" variation="modal" [loginMechanisms]="['email']"> </amplify-authenticator>
</ng-container>
