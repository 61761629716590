<h1 class="mb-4">Modifying {{ (account$ | async)?.name ?? "loading..." }}</h1>

<div class="grid grid-cols-2 gap-4">
  <div class="bg-white p-5 shadow" [formGroup]="contactForm">
    <h2>Contact Information</h2>

    <app-input formControlName="name" label="Name"></app-input>
    <app-input formControlName="company" label="Company"></app-input>
    <app-input formControlName="email" label="Email"></app-input>
    <app-input formControlName="phone" label="Phone"></app-input>

    <fieldset class="mb-4">
      <legend>Address</legend>
      <app-input formControlName="address1" label="Address"></app-input>
      <app-input formControlName="address2" label="Address Line 2"></app-input>

      <div class="grid grid-cols-3 gap-1.5">
        <app-input formControlName="city" label="City"></app-input>
        <app-input formControlName="state" label="State"></app-input>
        <app-input formControlName="zip" label="Zip"></app-input>
      </div>
    </fieldset>

    <app-button gradientOutline="green-to-blue" [disabled]="!contactForm.valid || !contactHasChanged" (buttonClick)="updateContactInfo()">Save</app-button>
  </div>

  <div class="bg-white p-5 shadow" [formGroup]="settingsForm">
    <h2>Settings</h2>

    <app-input formControlName="maxSmsSegments" label="Max SMS Segments" inputType="number"></app-input>
    <app-input formControlName="maxMmsSegments" label="Max MMS Segments" inputType="text"></app-input>
    <app-input formControlName="maxRetentionDays" label="Max Retention Days" inputType="text"></app-input>
    <app-input formControlName="requestThrottlePerSecond" label="Request Throttle Per Second" inputType="text"></app-input>
    <app-input formControlName="managementRequestThrottlePerSecond" label="Management Request Throttle Per Second" inputType="text"></app-input>

    <app-button gradientOutline="green-to-blue" [disabled]="!settingsForm.valid || !settingsHasChanged" (buttonClick)="updateSettings()">Save</app-button>
  </div>

  <div class="bg-white p-5 col-span-2 shadow" [formGroup]="pricingForm">
    <h2>Pricing</h2>

    <app-input formControlName="minimumMonthlyPrice" label="Minimum Monthly Price" inputType="number"></app-input>

    <h3 class="mb-2">Pricing Tiers</h3>
    <div class="grid gap-2" [formArrayName]="'tiers'">
      <ng-container *ngFor="let tier of tiersFormControls; let i = index">
        <div [formGroup]="tier" class="grid grid-flow-col gap-2 border-gray-300 border border-dashed pt-2">
          <div class="flex justify-center items-center">
            <p class="font-semibold">Tier {{ i + 1 }}</p>
          </div>
          <app-input formControlName="minSmsSegments" label="Min SMS Segments" inputType="number"></app-input>
          <app-input formControlName="smsSegmentPrice" label="SMS Segment Price" inputType="number"></app-input>
          <app-input formControlName="minMmsSegments" label="Min MMS Segments" inputType="number"></app-input>
          <app-input formControlName="mmsSegmentPrice" label="MMS Segment Price" inputType="number"></app-input>

          <button type="button" (click)="tiersForm.removeAt(i)">Remove</button>
        </div>
      </ng-container>
    </div>
    <p class="text-sm text-gray-500">
      Hermes pricing is based on tiers. Once a customer has reached the minimum number of segments for a tier, the total price for that tier will be charged.
    </p>

    <details class="mb-4">
      <summary>Pricing Calculator</summary>
      <app-pricing-tier-calculator></app-pricing-tier-calculator>
    </details>

    <app-button gradientOutline="green-to-blue">Save</app-button>
  </div>
</div>
