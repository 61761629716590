import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() message!: string;
  @Input() title!: string;
  @Input() type!: 'info' | 'success' | 'warning' | 'danger' | 'dark';
  @Input() icon?: string;

  elementId = Math.random().toString(36).substring(2, 11);
  show: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.show = false;
  }
}
