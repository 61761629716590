import { ChangeDetectorRef, Component, OnInit, AfterViewInit, Pipe, PipeTransform, ViewChild, OnDestroy, Inject, Optional } from '@angular/core';
import {
  Account,
  AccountDTO,
  ApiClient,
  API_BASE_URL,
  ExportMessagesRequest,
  ListAccountsResponse,
  MessageDeliveryStatus,
  MessageDTO,
  MessageService,
  MessageServiceDTO,
} from '../../api/api.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, finalize, Observable, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModalComponent } from '../../components/modal/modal.component';

@Component({
  selector: 'app-messages',
  templateUrl: './account-messages-page-component.html',
  styleUrls: ['./account-messages-page-component.scss'],
})
export class AccountMessagesPageComponent implements OnInit, OnDestroy, AfterViewInit {
  statusObj = MessageDeliveryStatus;
  accountId?: string;

  public messages?: MessageDTO[];
  messages$ = new BehaviorSubject<MessageDTO[]>([]);
  message?: MessageDTO;

  public services?: MessageServiceDTO[];
  services$ = new BehaviorSubject<MessageServiceDTO[]>([]);

  public accounts?: Account[];
  accounts$ = new BehaviorSubject<Account[] | undefined>(undefined);

  searchPhoneTo?: string = '';
  searchPhoneFrom?: string = '';
  fromDate: Date = new Date();
  toDate: Date = new Date();
  status?: MessageDeliveryStatus;
  serviceId?: string;
  selected?: string = 'selected';

  accountRestrictedMode?: boolean;

  @ViewChild('showMoreDetails') showMoreDetails?: ModalComponent;

  constructor(
    private http: HttpClient,
    private api: ApiClient,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    @Optional() @Inject(API_BASE_URL) private readonly baseUrl?: string
  ) {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : 'https://localhost:5001';
  }

  ngOnInit(): void {
    this.fromDate.setDate(this.fromDate.getDate() - 1);

    this.accountRestrictedMode = Object.keys(this.route.snapshot.params).length > 0;

    if (this.accountRestrictedMode) {
      this.route.params.subscribe(({ accountId }) => {
        this.accountId = accountId;
        this.api.listServices(accountId).subscribe((services) => {
          this.services = services;
          this.services$.next(services);
        });
      });
    } else {
      this.api.listAccounts2().subscribe((resp) => {
        this.accounts = resp.accounts;
        this.accounts$.next(resp.accounts);
      });
    }
    this.callApi();
  }

  searchClick(): void {
    this.callApi();
  }

  callApi() {
    this.api
      .searchMessages({
        accountId: this.accountId,
        messageServiceId: this.serviceId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        toPhone: this.searchPhoneTo,
        fromPhone: this.searchPhoneFrom,
        status: this.status,
      })
      .subscribe((messages) => {
        this.messages = messages;
        this.messages$.next(messages);
      });
  }

  exportCSV() {
    this.exportMessages({
      accountId: this.accountId,
      messageServiceId: this.serviceId,
      fromDate: this.fromDate,
      toDate: this.toDate,
      toPhone: this.searchPhoneTo,
      fromPhone: this.searchPhoneFrom,
      status: this.status,
      limit: 0,
      offset: 0,
    });
  }

  exporting = false;
  exportMessages(exportMessagesRequest: ExportMessagesRequest) {
    this.exporting = true;
    this.api
      .exportMessages(exportMessagesRequest)
      .pipe(
        tap((response) => {
          console.log(response.downloadUrl);
          window.open(response.downloadUrl, '_blank');
        }),
        finalize(() => {
          console.log('exporting complete');
          this.exporting = false;
          this.changeDetector.detectChanges();
        })
      )
      .subscribe();

    // let url_ = this.baseUrl + '/export-messages';
    // const content_ = JSON.stringify(exportMessagesRequest);
    // this.http.post(url_, exportMessagesRequest, { responseType: 'blob' as 'json' }).subscribe((response: any) => {
    //   console.log(response);
    //   let dataType = response.type;
    //   let binaryData = [];
    //   binaryData.push(response);
    //   let downloadLink = document.createElement('a');
    //   downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    //   downloadLink.setAttribute('download', 'messages.csv');
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    // });
  }

  ngAfterViewInit() {
    // this.accounts;
  }

  ngOnDestroy() {
    this.accounts$.unsubscribe();
    this.messages$.unsubscribe();
    this.services$.unsubscribe();
  }

  getKeys(obj: any) {
    return Object.keys(obj);
  }

  fromDateChanged($event: any) {
    this.fromDate = new Date(new Date($event));
  }

  toDateChanged($event: any) {
    this.toDate = new Date(new Date($event));
  }

  serviceChanged($event: any) {
    if ($event === 'undefined') $event = undefined;
    this.serviceId = $event;
  }

  statusChanged($event: any) {
    if ($event === 'undefined') $event = undefined;
    this.status = $event;
  }

  phoneFromChanged($event: any) {
    this.searchPhoneFrom = $event;
  }

  phoneToChanged($event: any) {
    this.searchPhoneTo = $event;
  }

  accountChanged($event: any) {
    if ($event === 'undefined') $event = undefined;

    this.accountId = $event;
    this.serviceId = undefined;

    this.services = [];
    this.services$.next(this.services);

    if (this.accountId) {
      this.api.listServices($event).subscribe((services) => {
        this.services = services;
        this.services$.next(services);
      });
    }
  }

  openMoreDetails(message: MessageDTO) {
    this.message = message;
    this.showMoreDetails!.show();
  }
}
