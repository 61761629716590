import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AccountDTO, ApiClient, MessageServiceDTO, VendorDTO } from '../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, finalize, of, tap } from 'rxjs';
import { ModalComponent } from '../../components/modal/modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  templateUrl: './account-services-page.component.html',
  styleUrls: ['./account-services-page.component.scss'],
})
export class AccountServicesPageComponent implements OnInit, OnDestroy {
  accountId: string = '';
  private services?: MessageServiceDTO[];
  services$ = new BehaviorSubject<MessageServiceDTO[]>([]); 
 
  private vendors?: VendorDTO[];
  vendors$ = new BehaviorSubject<VendorDTO[]>([]);

  searchTerm: string = '';
  @ViewChild('addServiceModal') addServiceModal?: ModalComponent;

  newServiceForm = new FormGroup({
    vendor: new FormControl(''),
    name: new FormControl(''),
    inboundCallbackPhone: new FormControl(''),
    inboundCallbackUrl: new FormControl(''),
    customerValidationHookUrl: new FormControl(''),
    customerStatusWebhookUrl: new FormControl('')
  });

  constructor(private api: ApiClient, private route: ActivatedRoute, private changeDetector: ChangeDetectorRef,private toastService: HotToastService) {}
  
  ngOnDestroy(): void {
    this.services$.unsubscribe();
    this.vendors$.unsubscribe();
  }

  ngOnInit(): void {
    console.log(this.route.snapshot.params);
    this.route.params.subscribe(({ accountId }) => {
      this.accountId = accountId;
      this.api.listServices(accountId).subscribe((services) => {
        this.services = services;
        this.services$.next(services);
        this.changeDetector.detectChanges();
      });

      this.api.listVendors().subscribe((vendors) => {
        this.vendors = vendors;
        this.vendors$.next(vendors);
        this.changeDetector.detectChanges();
      });

    });
  }

  searchTermChanged(term: string) {
    this.searchTerm = term;

    if (!this.services) this.services$.next([]);
    else if (!this.searchTerm) this.services$.next(this.services);
    else
      this.services$.next(
        this.services.filter(
          (s) =>
            s.name?.toLowerCase().includes(this.searchTerm!.toLowerCase()) ||
            s.id?.toLowerCase().includes(this.searchTerm!.toLowerCase()) ||
            s.inboundCallbackPhone?.toLowerCase().includes(this.searchTerm!.toLowerCase())
        )
      );
  }

  showMessageServiceModal() {
     this.addServiceModal?.show();
  }

  addService() {
    this.api.createService( this.accountId,
      {
        name:  this.newServiceForm.get('name')?.value,
        vendorId: this.newServiceForm.get('vendor')?.value,
        theAccountId: this.accountId,
        enabled: true,
        customerValidationHookUrl: this.newServiceForm.get('customerValidationHookUrl')?.value,
        customerStatusWebhookUrl: this.newServiceForm.get('customerStatusWebhookUrl')?.value,
        inboundCallbackPhone: this.newServiceForm.get('inboundCallbackPhone')?.value,
        inboundCallbackUrl: this.newServiceForm.get('inboundCallbackUrl')?.value
      }
    ).pipe(
      this.toastService.observe({
        loading: 'Adding...',
        success: (s) => {
          return `Added!`;
        },
        error: (e) => {
          console.log(e);
          return `Failed to add! ${e.message}`;
        },
      }),
      tap(() => {}),
      catchError((error) => of(error)),
      finalize(() => {
        this.addServiceModal?.hide();
      })
    )
    .subscribe((service) => {
      this.services?.push(service);
      this.services$.next(this.services!);
      this.changeDetector.detectChanges();
    });
  }
}
