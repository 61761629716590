import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { AccountDTO, ApiClient, GetStatusesResponse, MessageServiceDTO } from '../../api/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-analytics-page',
  templateUrl: './account-analytics-page.component.html',
  styleUrls: ['./account-analytics-page.component.scss'],
})
export class AccountAnalyticsPageComponent implements OnInit {
  chart?: any;
  options?: EChartsOption;

  delivered: any[] = [];
  failed: any[] = [];
  undelivered: any[] = [];
  unknown: any[] = [];
  rejected: any[] = [];
  expired: any[] = [];
  sent: any[] = [];
  received: any[] = [];
  spam: any[] = [];
  ruleValidation: any[] = [];
  now = new Date();

  showDays = 0;
  showServiceId: string | null = null;

  totalSent = 0;
  totalFailed = 0;
  totalDelivered = 0;
  usageGross = 0;

  account?: AccountDTO;
  accountId?: string;
  statuses?: GetStatusesResponse;
  services?: MessageServiceDTO[];
  constructor(private api: ApiClient, private route: ActivatedRoute) {}

  randomData(arr: any[], mod: number) {
    const value = Math.random() * 10000 * mod;
    if (value <= 0) return;
    arr.push({
      name: this.now.toString(),
      value: [this.now.toISOString(), Math.round(value)],
    });
  }

  pushValue(array: any[], interval: Date, value: number) {
    interval = new Date(interval);
    array.push({
      name: interval.toString(),
      value: [interval.toISOString(), value],
    });
  }
  resetArrays() {
    this.delivered = [];
    this.failed = [];
    this.undelivered = [];
    this.unknown = [];
    this.rejected = [];
    this.expired = [];
    this.sent = [];
    this.received = [];
    this.spam = [];
    this.ruleValidation = [];

    this.totalSent = 0;
    this.totalFailed = 0;
    this.totalDelivered = 0;
    this.usageGross = 0;
  }

  loading = false;
  processStats(startDate: Date, endDate: Date) {
    console.log('Processing stats', this.showServiceId);
    this.loading = true;
    this.api
      .getStats(this.accountId!, {
        startTime: startDate,
        endTime: endDate,
        messageServiceId: this.showServiceId ?? undefined,
      })
      .subscribe(
        (stats) => {
          this.resetArrays();
          // Iterate through the stats and add them to the chart
          for (const stat of stats) {
            this.pushValue(this.delivered, stat.start!, stat.delivered!);
            this.pushValue(this.failed, stat.start!, stat.failed!);
            this.pushValue(this.undelivered, stat.start!, stat.undelivered!);
            this.pushValue(this.unknown, stat.start!, stat.unknown!);
            this.pushValue(this.rejected, stat.start!, stat.rejected!);
            this.pushValue(this.expired, stat.start!, stat.expired!);
            // this.pushValue(this.sent, stat.start!, stat.sent!);
            this.pushValue(this.received, stat.start!, stat.received!);
            this.pushValue(this.spam, stat.start!, stat.spam!);
            this.pushValue(this.ruleValidation, stat.start!, stat.failedRuleValidation!);

            this.pushValue(this.sent, stat.start!, stat.total!);
            this.totalSent += stat.total || 0;
            this.totalFailed += stat.failed || 0;
            this.totalDelivered += stat.delivered || 0;
            this.usageGross += stat.gross || 0;
          }

          console.log('Updating chart...');
          this.chart?.setOption({
            series: [
              { data: this.sent },
              { data: this.delivered },
              { data: this.failed },
              { data: this.received },
              { data: this.undelivered },
              { data: this.rejected },
              { data: this.expired },
              { data: this.spam },
              { data: this.unknown },
              { data: this.ruleValidation },
            ],
          });
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          console.error(err);
        },
        () => (this.loading = false)
      );

    this.api.getStatuses(this.accountId!, this.showServiceId ?? undefined).subscribe((statuses) => (this.statuses = statuses));
  }

  ngOnInit(): void {
    this.route.params.subscribe(({ accountId }) => {
      this.accountId = accountId;

      let startOfToday = new Date();

      startOfToday.setDate(startOfToday.getDate() - this.showDays);
      startOfToday.setHours(0, 0, 0, 0);

      // Make sure it's "start of today" at *midnight of the current timezone*
      // startOfToday = new Date(startOfToday.toISOString());

      this.api.listServices(this.accountId!).subscribe((services) => (this.services = services));

      this.processStats(startOfToday, new Date());
    });

    this.options = {
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        // @ts-ignore
        containLabel: true,
      },
      legend: {
        data: ['Requested', 'Delivered', 'Failed', 'Undelivered', 'Unknown', 'Rejected', 'Expired', 'Received', 'Spam', 'Rule Validation'],
        // align: 'left',
        // orient: 'vertical',
        // right: 10,
        // top: 'center',
      },

      toolbox: {
        // right: '3%',
        feature: {
          // dataZoom: {
          //   yAxisIndex: 'none',
          // },
          restore: {},
          saveAsImage: {},
          dataView: {},
          // magicType: {
          //   type: ['line', 'bar', 'stack'],
          // },
        },
      },

      // dataZoom: [
      //   {
      //     type: 'inside',
      //     start: 0,
      //     end: 10,
      //   },
      //   {
      //     start: 0,
      //     end: 10,
      //   },
      // ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        // boundaryGap: [0, '100%'],
        // splitLine: {
        //   show: false,
        // },
      },
      // color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
      series: [
        { name: 'Requested', type: 'line', data: this.sent, stack: 'Total' },
        { name: 'Delivered', type: 'line', data: this.delivered, stack: 'Total' },
        { name: 'Failed', type: 'line', data: this.failed, stack: 'Total' },
        { name: 'Received', type: 'line', data: this.received, stack: 'Total' },
        { name: 'Undelivered', type: 'line', data: this.undelivered, stack: 'Total' },
        { name: 'Rejected', type: 'line', data: this.rejected, stack: 'Total' },
        { name: 'Expired', type: 'line', data: this.expired, stack: 'Total' },
        { name: 'Spam', type: 'line', data: this.spam, stack: 'Total' },
        { name: 'Unknown', type: 'line', data: this.unknown, stack: 'Total' },
        { name: 'Rule Validation', type: 'line', data: this.ruleValidation, stack: 'Total' },
      ],

      animationEasing: 'elasticOut',
    };
  }

  changeShownDays($event: any) {
    this.showDays = parseInt($event);

    let startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    startOfDay.setDate(startOfDay.getDate() - this.showDays);

    this.processStats(startOfDay, new Date());
  }
  changeServiceSelection($event: any) {
    this.showServiceId = $event;
    let startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    startOfDay.setDate(startOfDay.getDate() - this.showDays);

    this.processStats(startOfDay, new Date());
  }
  getCounts(dict: any, sort = false) {
    if (!dict) return [];

    let ret = [];
    for (const key of Object.keys(dict)) {
      // @ts-ignore
      ret.push({ name: key, value: dict[key] as number });
    }

    if (sort) {
      // Sort by name
      ret = ret.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

    return ret;
  }

  getStatusCounts() {
    return this.getCounts(this.statuses?.statusCounts);
  }

  getValidationCounts() {
    return this.getCounts(this.statuses?.validationReasons, true);
  }

  getErrorReasonCounts() {
    return this.getCounts(this.statuses?.errorReasons, true);
  }

  getQueueCounts() {
    return this.getCounts(this.statuses?.queueCounts);
  }
}
