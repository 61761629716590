<div class="flex flex-row justify-between">
  <div>
    <h1>{{ service.name }}</h1>
    <p class="text-xs text-gray-500">{{ service.id }}</p>
  </div>

  <div class="text-right">
    <p class="text-xs text-gray-500">Created: {{ service.createdAt | date: "medium" }}</p>
    <p class="text-xs text-gray-500">Last Updated: {{ service.updatedAt | date: "medium" }}</p>
  </div>
</div>

<div class="py-5">
  <h2>Message Templates</h2>
  <div>
    <table class="w-full text-sm text-center text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3 text-center">Id</th>
          <th scope="col" class="px-6 py-3 text-center">Name</th>
          <th scope="col" class="px-6 py-3 text-center">Template</th>
          <th scope="col" class="px-6 py-3 text-center">Created</th>
          <th scope="col" class="px-6 py-3 text-center">Updated</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" *ngFor="let t of templates">
          <td class="px-6 py-1 whitespace-nowrap">
            <a [routerLink]="['template', t.id]" class="text-blue-600 dark:text-blue-500 hover:underline font-mono">{{ t.id }}</a>
          </td>
          <td class="px-6 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ t.name }}</td>
          <td class="text-center p-2">
            <p class="preformatted h-[2.75em] overflow-hidden hover:h-full" [innerHTML]="getTemplateHtml(t)"></p>
          </td>
          <td class="text-center px-2 py-1">{{ t.createdAt | date: "yyyy-MM-dd":"UTC" }}</td>
          <td class="text-center px-2 py-1">{{ t.updatedAt | date: "yyyy-MM-dd":"UTC" }}</td>
          <td>
            <!-- Buttons for Update, Delete, and Test Render -->
            <div class="flex flex-row justify-between">
              <app-button variant="outline" (buttonClick)="testRender(t)" size="xs" buttonText="Render">
                <i class="fa-regular fa-magnifying-glass fa-fw"></i>
              </app-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="my-7 w-full flex justify-end">
      <app-button [routerLink]="['template', '']" buttonText="Add Template"></app-button>
    </div>
  </div>
</div>

<div class="py-5">
  <h2>Vendor Integrations</h2>
  <div>
    <table class="w-full text-sm text-center text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3 text-center">Id</th>
          <th scope="col" class="px-6 py-3 text-center">Vendor</th>
          <th scope="col" class="px-6 py-3 text-center">Service ID</th>
          <th scope="col" class="px-6 py-3 text-center">Vendor Account ID</th>
          <th scope="col" class="px-6 py-3 text-center">Created</th>
          <th scope="col" class="px-6 py-3 text-center">Updated</th>
          <th scope="col" class="px-6 py-3 text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" *ngFor="let integration of integrations$ | async">
          <td class="px-6 py-4">
            <a [routerLink]="[integration.id]" class="text-blue-600 dark:text-blue-500 hover:underline" style="font-family: monospace">{{ integration.id }}</a>
          </td>
          <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ integration.vendor?.name }}</td>
          <td class="text-center">
            <ng-container *ngIf="integration.vendor?.name == 'Twilio'; else defaultDisplay">
              <a
                target="_blank"
                href="https://console.twilio.com/us1/service/sms/{{
                  integration.vendorServiceId
                }}/messaging-service-properties?frameUrl=%2Fconsole%2Fsms%2Fservices%2F{{ integration.vendorServiceId }}%2Fproperties%3Fx-target-region%3Dus1"
              >
                {{ integration.vendorServiceId }}
              </a>
            </ng-container>
            <ng-template #defaultDisplay>
              {{ integration.vendorServiceId }}
            </ng-template>
          </td>
          <td class="text-center">{{ integration.vendorAccountId }}</td>
          <td class="text-center">{{ integration.createdAt | date: "yyyy-MM-dd hh:mm:ss":"UTC" }}</td>
          <td class="text-center">{{ integration.updatedAt | date: "yyyy-MM-dd hh:mm:ss":"UTC" }}</td>
          <td class="px-6 pt-4 pb-2">
            <app-button [routerLink]="[integration.id]">Edit</app-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="my-7 w-full flex justify-end">
      <app-button *ngIf="hasIntegration == false" type="button" (buttonClick)="addIntegration()" buttonText="Add Integration"></app-button>
    </div>
  </div>
</div>

<app-modal #integrationModal>
  <h1>Add Vendor Integration</h1>
  <p>Adding a Vendor Integration to this Message Service</p>

  <div class="flex-col p-2">
    <label for="Vendors" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Vendor</label>
    <select [ngModel]="vendorId" 
    class="min-w-[20ch] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
    (ngModelChange)="vendorChanged($event)" id="vendors">
      <option *ngFor="let vendor of vendors$ | async" [ngValue]="vendor">{{vendor.name}}</option>
    </select>
  </div>

  <div class="col p-2">
    <app-input
        inputType="text"
        placeholder="VendorAccountId"
        label="VendorAccountId"
        [ngModel]="vendorAccountId"
        (ngModelChange)="vendorAccountIdChanged($event)"
    ></app-input>
  </div>

  <div class="col p-2">
    <app-input
      inputType="text"
      placeholder="VendorServiceId"
      label="VendorServiceId"
      [ngModel]="vendorServiceId"
      (ngModelChange)="vendorServiceIdChanged($event)"
  ></app-input>
  </div>

  <div class="flex-col p-2 my-7">
      <app-button (buttonClick)="addThisIntegration()" buttonText="Add"></app-button>
  </div>
</app-modal>

<app-modal #previewModal title="Text preview">
  <div class="px-4 pb-4">
    <app-phone-preview #phonePreview></app-phone-preview>
  </div>
</app-modal>
