<div
  class="flex p-4 mb-4 text-sm dark:bg-gray-700 dark:text-gray-300 border-t-4"
  [ngClass]="{
    hidden: !show,
    'text-blue-700 border-blue-700 bg-blue-100 dark:bg-blue-100 ': type === 'info',
    'text-red-700 border-red-700 bg-red-100 dark:bg-red-100 ': type === 'danger',
    'text-green-700 border-green-700 bg-green-100 dark:bg-green-100 ': type === 'success',
    'text-yellow-700 border-yellow-700 bg-yellow-100 dark:bg-yellow-100 ': type === 'warning',
    'text-gray-700 border-gray-700 bg-gray-100 dark:bg-gray-100 ': type === 'dark'
  }"
  role="alert"
>
  <i class="fa-regular fa-fw {{ icon }} inline flex-shrink-0 mr-3 w-5 h-5" *ngIf="!!icon"></i>
  <div>
    <span class="font-medium">{{ title }}</span> {{ message }}
  </div>
  <button
    type="button"
    class="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 inline-flex h-8 w-8"
    [ngClass]="{
      'text-blue-500 border-blue-700 bg-blue-100 dark:bg-blue-200 hover:bg-blue-200 dark:hover:bg-blue-300 focus:ring-blue-400 ': type === 'info',
      'text-red-500 border-red-700 bg-red-100 dark:bg-red-200 hover:bg-red-200 dark:hover:bg-red-300 focus:ring-red-400 ': type === 'danger',
      'text-green-500 border-green-700 bg-green-100 dark:bg-green-200 hover:bg-green-200 dark:hover:bg-green-300 focus:ring-green-400 ': type === 'success',
      'text-yellow-500 border-yellow-700 bg-yellow-100 dark:bg-yellow-200 hover:bg-yellow-200 dark:hover:bg-yellow-300 focus:ring-yellow-400':
        type === 'warning',
      'text-gray-500 border-gray-700 bg-gray-100 dark:bg-gray-200 hover:bg-gray-200 dark:hover:bg-gray-300 focus:ring-gray-400 ': type === 'dark'
    }"
    (click)="close()"
    aria-label="Close"
  >
    <span class="sr-only">Dismiss</span>
    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clip-rule="evenodd"
      ></path>
    </svg>
  </button>
</div>
