import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pricing-tier-calculator',
  templateUrl: './pricing-tier-calculator.component.html',
  styleUrls: ['./pricing-tier-calculator.component.scss'],
})
export class PricingTierCalculatorComponent implements OnInit {
  form = new FormGroup({
    sentSegments: new FormControl(1000000),

    tiers: new FormArray([
      new FormGroup({
        numSegments: new FormControl(10000),
        price: new FormControl(0.015),
      }),
      new FormGroup({
        numSegments: new FormControl(100000),
        price: new FormControl(0.01),
      }),
      new FormGroup({
        numSegments: new FormControl(1000000),
        price: new FormControl(0.005),
      }),
    ]),
  });

  constructor() {}

  ngOnInit(): void {}

  getTier(index: number): FormGroup {
    return (this.form.get('tiers') as FormArray).at(index) as FormGroup;
  }

  getMetTierPricing() {
    let pricingSummary = '';

    let sentSegments = this.form.get('sentSegments')!.value as number;
    let tiers = this.form.get('tiers')!.value as { numSegments: number; price: number }[];

    for (let i = 0; i < tiers.length; i++) {
      pricingSummary += `Tier ${i + 1}: ${tiers[i].numSegments} segments: $${parseFloat(tiers[i].price.toString()).toFixed(5)} per segment\n`;
    }

    let tierMatched = 0;
    let totalPrice = 0;
    for (let i = 0; i < tiers.length; i++) {
      // If we're under the min segment count, or we're the last tier, calculate the price
      if (sentSegments < tiers[i].numSegments || i == tiers.length - 1) {
        tierMatched = i + 1;
        totalPrice += sentSegments * tiers[i].price;
        pricingSummary += `\nTier ${tierMatched}: ${sentSegments} segments: $${totalPrice.toFixed(5)}\n`;
        break;
      }
    }
    pricingSummary += `\nTotal: $${totalPrice.toFixed(5)} at Tier ${tierMatched} pricing`;
    return pricingSummary;
  }

  getAwsTierPricing() {
    let pricingSummary = '';

    for (let i = 0; i < this.form.get('tiers')!.value.length; i++) {
      let tier = this.getTier(i);
      console.log(tier.get('price'));
      pricingSummary += `${i === 0 ? 'First' : 'Next'} ${tier.get('numSegments')!.value} segments: $${
        parseFloat(tier.get('price')?.value)?.toFixed(5) ?? 0
      } per segment\n`;
    }

    pricingSummary += '\n';

    // Now, take the total segments from 'sentSegments', and determine the pricing for each tier, if the number of segments is greater than the **previous** tier's number of segments.
    let sentSegments = this.form.get('sentSegments')!.value as number;
    let tiers = this.form.get('tiers')!.value as { numSegments: number; price: number }[];
    let totalPrice = 0;

    let remainingSegments = sentSegments;
    for (let i = 0; i < tiers.length; i++) {
      if (remainingSegments < 0) break;

      let tier = tiers[i];
      let numSegments = Math.min(tier.numSegments, remainingSegments);
      if (i === tiers.length - 1) numSegments = remainingSegments;
      let price = numSegments * tier.price;
      totalPrice += price;
      remainingSegments -= numSegments;

      pricingSummary += `${i === 0 ? 'First' : 'Next'} ${numSegments} segments: $${price.toFixed(5)}\n`;
    }

    pricingSummary += `\nTotal: $${totalPrice.toFixed(5)}`;

    return pricingSummary;
  }
}
