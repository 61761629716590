<!--<ng-content></ng-content>-->

<button [class]="getCssClass()" [type]="buttonType" [disabled]="disabled" (click)="buttonClick.emit($event)">
  <div *ngIf="!(wrapper?.innerHTML || '').trim()">{{ buttonText }}</div>

  <div #wrapper>
    <ng-content></ng-content>
  </div>
</button>

<!--&lt;!&ndash; Note: The following is due to issues with @apply and 'group' classes in TWCSS. See: https://github.com/tailwindlabs/tailwindcss/issues/2848 &ndash;&gt;-->
<!--<button-->
<!--  *ngIf="gradientOutline == 'purple-to-blue'"-->
<!--  (click)="buttonClick.emit($event)"-->
<!--  [type]="buttonType"-->
<!--  [disabled]="disabled"-->
<!--  class="btn relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 {{-->
<!--    buttonClass-->
<!--  }}"-->
<!--&gt;-->
<!--  <span class="relative transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 btn-{{ size }}">-->
<!--    &lt;!&ndash;<ng-content></ng-content>&ndash;&gt;-->
<!--  </span>-->
<!--</button>-->
<!--<button-->
<!--  *ngIf="gradientOutline == 'cyan-to-blue'"-->
<!--  (click)="buttonClick.emit($event)"-->
<!--  [type]="buttonType"-->
<!--  [disabled]="disabled"-->
<!--  class="btn relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 {{-->
<!--    buttonClass-->
<!--  }}"-->
<!--&gt;-->
<!--  <span class="relative transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 btn-{{ size }}">-->
<!--    &lt;!&ndash;    <ng-content></ng-content>&ndash;&gt;-->
<!--  </span>-->
<!--</button>-->
<!--<button-->
<!--  *ngIf="gradientOutline == 'green-to-blue'"-->
<!--  (click)="buttonClick.emit($event)"-->
<!--  [type]="buttonType"-->
<!--  [disabled]="disabled"-->
<!--  class="btn relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 {{-->
<!--    buttonClass-->
<!--  }}"-->
<!--&gt;-->
<!--  <span class="relative btn-{{ size }} transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">-->
<!--    &lt;!&ndash;    <ng-content></ng-content>&ndash;&gt;-->
<!--  </span>-->
<!--</button>-->
<!--<button-->
<!--  *ngIf="gradientOutline == 'purple-to-pink'"-->
<!--  (click)="buttonClick.emit($event)"-->
<!--  [type]="buttonType"-->
<!--  [disabled]="disabled"-->
<!--  class="btn relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 {{-->
<!--    buttonClass-->
<!--  }}"-->
<!--&gt;-->
<!--  <span class="relative btn-{{ size }} transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">-->
<!--    &lt;!&ndash;    <ng-content></ng-content>&ndash;&gt;-->
<!--  </span>-->
<!--</button>-->
<!--<button-->
<!--  *ngIf="gradientOutline == 'pink-to-orange'"-->
<!--  (click)="buttonClick.emit($event)"-->
<!--  [type]="buttonType"-->
<!--  [disabled]="disabled"-->
<!--  class="btn relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 {{-->
<!--    buttonClass-->
<!--  }}"-->
<!--&gt;-->
<!--  <span class="relative btn-{{ size }} transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">-->
<!--    &lt;!&ndash;    <ng-content></ng-content>&ndash;&gt;-->
<!--  </span>-->
<!--</button>-->
<!--<button-->
<!--  *ngIf="gradientOutline == 'teal-to-lime'"-->
<!--  (click)="buttonClick.emit($event)"-->
<!--  [type]="buttonType"-->
<!--  [disabled]="disabled"-->
<!--  class="btn relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-300 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800 {{-->
<!--    buttonClass-->
<!--  }}"-->
<!--&gt;-->
<!--  <span class="relative btn-{{ size }} transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">-->
<!--    &lt;!&ndash;    <ng-content></ng-content>&ndash;&gt;-->
<!--  </span>-->
<!--</button>-->
<!--<button-->
<!--  *ngIf="gradientOutline == 'red-to-yellow'"-->
<!--  (click)="buttonClick.emit($event)"-->
<!--  [type]="buttonType"-->
<!--  [disabled]="disabled"-->
<!--  class="btn relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 {{-->
<!--    buttonClass-->
<!--  }}"-->
<!--&gt;-->
<!--  <span class="relative btn-{{ size }} transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">-->
<!--    &lt;!&ndash;    <ng-content></ng-content>&ndash;&gt;-->
<!--  </span>-->
<!--</button>-->
