import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApiClient, MessageServiceDTO, MessageTemplateDTO } from '../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { PhonePreviewComponent } from '../../components/phone-preview/phone-preview.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { auditTime, catchError, switchMap, tap, throwError } from 'rxjs';
import { number } from 'echarts';

@Component({
  selector: 'message-template-editor-page',
  templateUrl: './message-template-editor-page.component.html',
  styleUrls: ['./message-template-editor-page.component.scss'],
})
export class MessageTemplateEditorPageComponent implements OnInit {
  private serviceId!: string;
  private accountId!: string;
  private templateId!: string;
  template?: MessageTemplateDTO;
  templateError?: string;
  params: any;
  action: string = 'Editing';
  @ViewChild('phonePreview') phonePreview?: PhonePreviewComponent;

  editForm = this.fb.group({
    name: [''],
    template: [''],
    params: this.fb.array([]),
  });

  addParameter(key: string, value: unknown) {
    const grp = this.editForm.get('params') as FormArray;

    grp.push(
      this.fb.group({
        key: [key],
        value: [value],
      })
    );
  }

  removeParameter(key: string) {
    const grp = this.editForm.get('params') as FormArray;

    grp.removeAt(grp.controls.findIndex((x) => x.value.key === key));
  }

  get paramsControl() {
    return this.editForm.get('params') as FormArray;
  }

  get paramsControls() {
    return this.paramsControl.controls as FormGroup[];
  }

  cleanString(str?: string) {
    str = str?.replace('"[', '[');
    str = str?.replace(']"', ']');
    str = str?.replace('\"', '"');
    console.log(str);
  return str;
}

  updateFromServer() {
    this.api.getMessageTemplate(this.accountId, this.serviceId, this.templateId).subscribe((template) => {
      this.template = template;

      this.editForm.get('name')?.setValue(template.name);
      this.editForm.get('template')?.setValue(template.template);
 
      this.template.parameters = this.cleanString(this.template?.parameters);
      this.params = JSON.parse(this.template.parameters!);

      Object.entries(this.params).forEach(([key, value]) => {
        this.addParameter(key, value);
    })

      this.changeDetector.detectChanges();
    });
  }

  constructor(
    private api: ApiClient,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private toast: HotToastService,
    private fb: FormBuilder
  ) {
    // /accounts/:accountId/services/:serviceId/template/:templateId - accountId and serviceId are required
    // Get the full service info from the API

    this.route.params.subscribe(({ accountId, serviceId, templateId }) => {
      this.serviceId = serviceId;
      this.accountId = accountId;
      this.templateId = templateId;
      if(this.templateId)
      {
        this.action = 'Editing';
        this.updateFromServer();
      }
      else
        this.action = 'Adding';
    });

  
    if(this.templateId)
    {
      this.editForm.valueChanges
      .pipe(
        auditTime(500),
        switchMap(() => {
              return this.api.renderRawMessageTemplate(this.accountId!, this.serviceId!, this.templateId!, {
              template: this.editForm.get('template')!.value,
              model: JSON.stringify(this.paramsToObject()),
           });
          
        }),
        tap(({ rendered, errors }) => {
          this.templateError = errors;

          // If the message was rendered, wrap each "line" in a <p> tag
          this.phonePreview?.setMessage({
            sent: true,
            message: rendered?.replace(/\n/g, '<br>') ?? '',
          });
        })
      )
      .subscribe(() => {
        this.changeDetector.detectChanges();
      });
    }
  }

  ngOnInit(): void {}

  paramsToObject() {
    const paramsMap: any = {};
    this.paramsControls.forEach((x) => {
      // Try to convert the x.value.value to a number (if it's a number), boolean (if it's a boolean), or string otherwise
      // Also check if the value is a string, and it "looks like an array", and if so, convert it to an array

      let value = x.value.value;

      // When checking for a number, also make sure it's not a phone number
      if (!isNaN(Number(value)) && !value.startsWith('+')) {
        value = Number(value);
      } else if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      } else if (value === 'null') {
        value = null;
      } else if (value.startsWith('[') && value.endsWith(']')) {
        value = value.substring(1, value.length - 1).split(',');
      }

      paramsMap[x.value.key] = value;
    });
    return paramsMap;
  }

  save() {
    this.api
      .updateMessageTemplate(this.accountId!, this.serviceId!, this.templateId!, {
        template: this.editForm.get('template')!.value,
        name: this.editForm.get('name')!.value,
        parameters: JSON.stringify(this.paramsToObject()),
      })
      .pipe(
        tap(() => {
          this.toast.success('Template saved');
        }),
        catchError((err) => {
          this.toast.error(err.message);
          return throwError(err);
        })
      )
      .subscribe();
  }
  
  add() {
    this.api.createMessageTemplate(this.accountId!,this.serviceId!,{
      name: this.editForm.get('name')!.value,
      template: this.editForm.get('template')!.value,
      parameters: JSON.stringify(this.paramsToObject()),
    })
  .pipe(
    tap((template) => {
      this.template = template;
      this.templateId! = template.id!;
      this.action = 'Editing';
      this.toast.success('Template added');
    }),
    catchError((err) => {
      this.toast.error(err.message);
      return throwError(err);
    })
  )
  .subscribe();
  }
}
