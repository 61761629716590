import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { ClickerPageComponent } from './pages/clicker-page/clicker-page.component';
import { AuthGuard } from './auth.guard';
import { TenantManagementPageComponent } from './pages/tenant-management-page/tenant-management-page.component';
import { TenantEditorPageComponent } from './pages/tenant-management-page/tenant-editor-page/tenant-editor-page.component';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { AccountPageComponent } from './pages/account-page/account-page.component';
import { AccountAnalyticsPageComponent } from './pages/account-analytics-page/account-analytics-page.component';
import { AccountServicesPageComponent } from './pages/account-services-page/account-services-page.component';
import { AccountServiceManagementPageComponent } from './pages/account-service-management-page/account-service-management-page.component';
import { AccountMessagesPageComponent } from './pages/account-messages-page/account-messages-page-component';
import { MessageTemplateEditorPageComponent } from './pages/message-template-editor-page/message-template-editor-page.component';
import { VendorIntegrationManagementPageComponent } from './pages/account-service-management-page/vendor-integration-management-page/vendor-integration-management-page.component';
import { AccountCustomerInfoComponent } from './pages/account-customer-info-page/account-customer-info-component';
import { AccountContactManagementComponent } from './pages/account-contact-management-page/account-contact-management-component';

const routes: Routes = [
  { path: '', pathMatch: 'full', /*redirectTo: '/accounts',*/ component: IndexComponent, data: { roles: ['management'] } },
  {
    path: 'accounts',
    pathMatch: 'full',
    component: TenantManagementPageComponent,
    data: { roles: ['management'], title: 'Accounts' },
  },
  {
    path: 'message-search',
    pathMatch: 'full',
    component: AccountMessagesPageComponent,
    data: { roles: ['management'], title: 'Message Search' },
  },
  {
    path: 'accounts/:accountId',
    component: AccountPageComponent,
    data: { roles: ['management'], title: 'Account' },

    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'analytics',
      },
      {
        path: 'analytics',
        component: AccountAnalyticsPageComponent,
        data: { roles: ['management'], title: 'Analytics' },
      },
      {
        path: 'services',
        component: AccountServicesPageComponent,
        data: { roles: ['management'], title: 'Services' },
      },
      {
        path: 'services/:serviceId',
        pathMatch: 'full',
        component: AccountServiceManagementPageComponent,
        data: { roles: ['management'], title: 'Service' },
      },
      {
        path: 'services/:serviceId/:vendorIntegrationId',
        pathMatch: 'full',
        component: VendorIntegrationManagementPageComponent,
        data: { roles: ['management'], title: 'Vendor Integration' },
      },
      {
        path: 'services/:serviceId/template/:templateId',
        component: MessageTemplateEditorPageComponent,
        data: { roles: ['management'], title: 'Message Template' },
      },
      {
        path: 'services/:serviceId/template',
        component: MessageTemplateEditorPageComponent,
        data: { roles: ['management'], title: 'Message Template' },
      },
      {
        path: 'template',
        component: MessageTemplateEditorPageComponent,
        data: { roles: ['management'], title: 'Message Template' },
      },
      {
        path: 'contacts',
        component: AccountContactManagementComponent,
        data: { roles: ['management'], title: 'Contacts' },
      },
      {
        path: 'customer',
        component: AccountCustomerInfoComponent,
        data: { roles: ['management'], title: 'Customer' },
      },
      {
        path: 'customer/:accountId',
        component: TenantEditorPageComponent,
        data: { roles: ['management'], title: 'Customer' },
      },
      {
        path: 'messages',
        component: AccountMessagesPageComponent,
        data: { roles: ['management'], title: 'Messages' },
      },
    ],
  },
  {
    path: 'clicker',
    pathMatch: 'full',
    component: ClickerPageComponent,
    canActivate: [AuthGuard],
    data: { roles: ['clicker', 'management'], title: 'Clicker' },
  },
  { path: 'unauthorized', component: UnauthorizedPageComponent, data: { title: 'Unauthorized' } },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
