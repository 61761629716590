<div class="bg-white p-5 my-4" [formGroup]="form">
  <h1>Pricing Calculator</h1>

  <fieldset>
    <legend>Tiers</legend>
    <div [formGroup]="getTier(0)" class="grid grid-flow-col gap-2">
      <p>Tier 1</p>
      <app-input formControlName="numSegments" inputType="number" label="Segments"></app-input>
      <app-input formControlName="price" inputType="number" label="Price Per Segment"></app-input>
    </div>

    <div [formGroup]="getTier(1)" class="grid grid-flow-col gap-2">
      <p>Tier 2</p>
      <app-input formControlName="numSegments" inputType="number" label="Segments"></app-input>
      <app-input formControlName="price" inputType="number" label="Price Per Segment"></app-input>
    </div>

    <div [formGroup]="getTier(2)" class="grid grid-flow-col gap-2">
      <p>Tier 3</p>
      <app-input formControlName="numSegments" inputType="number" label="Segments"></app-input>
      <app-input formControlName="price" inputType="number" label="Price Per Segment"></app-input>
    </div>
  </fieldset>

  <app-input formControlName="sentSegments" inputType="number" label="Sent Segments"></app-input>

  <div class="bg-gray-50 border border-gray-300">
    <div class="grid grid-flow-col gap-2 p-4">
      <div>
        <h3>Pricing Based on AWS Tiers</h3>
        <pre>{{ getAwsTierPricing() }}</pre>
      </div>
      <div>
        <h3>Pricing Based on "Met" Tiers</h3>
        <pre>{{ getMetTierPricing() }}</pre>
      </div>
    </div>
  </div>

  <div class="text-sm">This tool is meant to show the two payment options. Once MRS/Finance has decided which billing option to use, this will be simplified.</div>
</div>
