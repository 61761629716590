import { ChangeDetectorRef, Component, DebugEventListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Account, ApiClient, MessageServiceSummary, VendorDTO } from '../../api/api.service';
import { BehaviorSubject, catchError, finalize, of, tap } from 'rxjs';
import { ModalComponent } from '../../components/modal/modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  templateUrl: './tenant-management-page.component.html', 
  styleUrls: ['./tenant-management-page.component.scss'],
})
export class TenantManagementPageComponent implements OnInit, OnDestroy {
  public tenants: Account[] = [];
  tenants$ = new BehaviorSubject<Account[] | undefined>(undefined);
  
  private vendors?: VendorDTO[];
  vendors$ = new BehaviorSubject<VendorDTO[]>([]);

  sendToTenant?: Account;
  tenantServices: MessageServiceSummary[] | undefined;
  selectedMessageService: any;
  testVal?: string;
  stringState: string = ''; 
  @ViewChild('sendSmsModal') sendSmsModal?: ModalComponent;
  @ViewChild('addAccountModal') addAccountModal?: ModalComponent;

  sendSmsForm = new FormGroup({
    messageService: new FormControl(''),
    tenant: new FormControl(''),
    message: new FormControl(''),
    toPhone: new FormControl(''),
    requireManual: new FormControl(false),
  });

  newAccountForm = new FormGroup({
    accountName: new FormControl(''),
    contactName: new FormControl(''),
    contactEmail: new FormControl(''),
    contactPhone: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zip: new FormControl(''),
    company: new FormControl(''),
    vendor: new FormControl(''),
    customerValidationHook: new FormControl('')
  });

  constructor(private http: ApiClient, private toastService: HotToastService,private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.http.listAccounts2().subscribe(
      (resp) => {
        console.log(resp);
        this.tenants = resp?.accounts!;
        this.tenants$.next(resp.accounts);
        this.changeDetector.detectChanges();
      },
      (error: any) => {
        console.log(error);
      }
    );

    this.http.listVendors().subscribe((vendors) => {
      this.vendors = vendors;
      this.vendors$.next(vendors);
      this.changeDetector.detectChanges();
    });
    
  }

  ngOnDestroy(): void {
    this.tenants$.unsubscribe();
    this.vendors$.unsubscribe();
  }

  setClipboard(id: string) {
    const textarea = document.createElement('textarea');
    textarea.value = id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    textarea.remove();
  }

  beginSendSmsModal(tenant: Account) {
    this.sendToTenant = tenant;
    this.sendSmsForm.patchValue({ tenant: tenant.id });
    this.sendSmsModal!.show();

    // Start loading the message services list, so the user can select one
    this.http.getAccountMessageService(tenant.id!).subscribe((tenants) => {
      this.tenantServices = tenants.messageServices;
      this.changeDetector.detectChanges();
    });
  }

  sendSms() {
    this.http
      .sendMessage({
        accountId: this.sendSmsForm.get('tenant')?.value,
        serviceId: this.sendSmsForm.get('messageService')?.value,
        toPhone: this.sendSmsForm.get('toPhone')?.value,
        body: this.sendSmsForm.get('message')?.value,
        automatic: !this.sendSmsForm.get('requireManual')?.value,
        customData:"{\"Database\":\"LU1\",\"Account\":6046318,\"Master\":5638500,\"Comaker\":0,\"ClientNumber\":1413}",
        reference: 'Sent via the Tenant Management page - ' + new Date().toISOString(),
      })
      .pipe(
        this.toastService.observe({
          loading: 'Sending...',
          success: (s) => {
            return `Sent!`;
          },
          error: (e) => {
            console.log(e);
            /*{"headers":{"normalizedNames":{},"lazyUpdate":null,"headers":{}},"status":0,"statusText":"Unknown Error","url":"https://localhost:5001/send","ok":false,"name":"HttpErrorResponse","message":"Http failure response for https://localhost:5001/send: 0 Unknown Error","error":{"isTrusted":true}}*/
            return `Failed to send! ${e.message}`;
          },
        }),
        tap(() => {}),
        catchError((error) => of(error)),
        finalize(() => {
          this.sendSmsModal?.hide();
        })
      )
      .subscribe();
  }

  showAccountModal() {
    this.addAccountModal?.show(); 
  }

  addAccount() {
    this.testVal = this.newAccountForm.get('contactPhone')?.value;

    if(this.testVal?.substring(0,2) != "+1")
    {
      this.toastService.error("phone number requires +1 prefix");
      return;
    }
     
    this.stringState = this.newAccountForm.get('state')?.value;
    this.stringState.toUpperCase();
    
    this.http.createAccount({
      name: this.newAccountForm.get('accountName')?.value ,
      contactName: this.newAccountForm.get('contactName')?.value,
      contactEmail: this.newAccountForm.get('contactEmail')?.value,
      contactPhone: this.newAccountForm.get('contactPhone')?.value,
      address: this.newAccountForm.get('address1')?.value,
      address2: this.newAccountForm.get('address2')?.value,
      city: this.newAccountForm.get('city')?.value,
      state: this.stringState,
      zip: this.newAccountForm.get('zip')?.value,
      company: this.newAccountForm.get('company')?.value,
      vendorName: this.newAccountForm.get('vendor')?.value,
      customerValidationHook: this.newAccountForm.get('customerValidationHook')?.value 
    }).pipe(
      this.toastService.observe({
        loading: 'Adding...',
        success: (s) => {
          return `Added!`;
        },
        error: (e) => {
          console.log(e);
          return `Failed to add! ${e.message}`;
        },
      }),
      tap(() => {}),
      catchError((error) => of(error)),
      finalize(() => {
        this.addAccountModal?.hide();
      })
    )
    .subscribe((account) => {
        this.tenants?.push(account);
       // this.tenants$.next(this.tenants);
        this.changeDetector.detectChanges();
    });
  }
}
