<div class="px-5 py-5 w-full flex flex-col items-center">
  <div class="w-full flex justify-end px-20 mb-5">
    <select [ngModel]="showDays" (ngModelChange)="changeShownDays($event)">
      <option value="0">Today</option>
      <option value="7">Last 7 days</option>
      <option value="30">Last 30 days</option>
      <option value="90">Last 90 days</option>
      <option value="365">Last 365 days</option>
    </select>

    <select [ngModel]="showServiceId" (ngModelChange)="changeServiceSelection($event)">
      <option [value]="null">All</option>
      <option *ngFor="let service of services" [value]="service.id">{{ service.name }}</option>
    </select>
  </div>

  <ng-container *ngIf="loading">
    <p>Loading...</p>
  </ng-container>
  <div class="grid grid-cols-4 gap-5 mb-5 px-20 w-full">
    <div class="bg-white shadow">
      <div class="text-center bg-green-600 p-3">
        <p class="text-white text-2xl">
          <i class="fas fa-message-dots fa-fw mr-2"></i>
          <span class="font-semibold">Requested</span>
        </p>
      </div>

      <div class="text-center py-5">
        <p class="text-xl font-bold h-full">{{ totalSent | number }}</p>
      </div>
    </div>

    <div class="bg-white shadow">
      <!-- Centered white text with a green background -->
      <div class="text-center bg-red-600 p-3">
        <p class="text-white text-2xl">
          <i class="fas fa-message-slash fa-fw mr-2"></i>
          <span class="font-semibold">Failed</span>
        </p>
      </div>

      <div class="text-center py-5">
        <p class="text-xl font-bold h-full">{{ totalFailed | number }}</p>
      </div>
    </div>

    <div class="bg-white shadow">
      <!-- Centered white text with a green background -->
      <div class="text-center bg-yellow-500 p-3">
        <p class="text-white text-2xl">
          <i class="fas fa-message-arrow-up-right fa-fw mr-2"></i>
          <span class="font-semibold">Delivered</span>
        </p>
      </div>

      <div class="text-center py-5">
        <p class="text-xl font-bold h-full">{{ totalDelivered | number }}</p>
      </div>
    </div> 

    <div class="bg-white shadow">
      <!-- Centered white text with a green background -->
      <div class="text-center bg-blue-600 p-3">
        <p class="text-white text-2xl">
          <i class="fas fa-message-dollar fa-fw mr-2"></i>
          <span class="font-semibold">Usage Gross</span>
        </p>
      </div>

      <div class="text-center py-5">
        <p class="text-xl font-bold h-full">{{ usageGross | currency }}</p>
      </div>
    </div>
  </div>

  <div class="w-full">
    <div echarts [options]="options!" class="demo-chart" [autoResize]="true" (chartInit)="chart = $event"></div>
  </div>

  <div class="w-full my-4">
    <h3>Today's Current Status Counts</h3>
    <dl class="grid grid-cols-2 gap-4 mt-2">
      <div class="shadow border p-4 border-slate-900">
        <h4>Statuses</h4>
        <div class="flex justify-between hover:bg-[#1da1f2]/5" *ngFor="let c of getStatusCounts()">
          <p>{{ c.name }}</p>
          <span class="font-semibold">{{ c.value | number }}</span>
        </div>
      </div>
      <div class="shadow border p-4 border-slate-900">
        <h4>Queue Counts</h4>
        <div class="flex justify-between hover:bg-[#1da1f2]/5" *ngFor="let c of getQueueCounts()">
          <p>{{ c.name }}</p>
          <span class="font-semibold">{{ c.value | number }}</span>
        </div>
      </div>
      <div class="shadow border p-4 border-slate-900">
        <h4>Validation Reasons</h4>
        <div class="flex justify-between hover:bg-[#1da1f2]/5" *ngFor="let c of getValidationCounts()">
          <p>{{ c.name }}</p>
          <span class="font-semibold">{{ c.value | number }}</span>
        </div>
      </div>
      <div class="shadow border p-4 border-slate-900">
        <h4>Error Reasons</h4>
        <div class="flex justify-between hover:bg-[#1da1f2]/5" *ngFor="let c of getErrorReasonCounts()">
          <p>{{ c.name }}</p>
          <span class="font-semibold">{{ c.value | number }}</span>
        </div>
      </div>
    </dl>
  </div>

  <!--  <div>-->
  <!--    <p>delivered: {{ delivered.length }}</p>-->
  <!--    <p>failed: {{ failed.length }}</p>-->
  <!--    <p>undelivered: {{ undelivered.length }}</p>-->
  <!--    <p>unknown: {{ unknown.length }}</p>-->
  <!--    <p>rejected: {{ rejected.length }}</p>-->
  <!--    <p>expired: {{ expired.length }}</p>-->
  <!--    <p>sent: {{ sent.length }}</p>-->
  <!--    <p>received: {{ received.length }}</p>-->
  <!--    <p>spam: {{ spam.length }}</p>-->
  <!--    <p>ruleValidation: {{ ruleValidation.length }}</p>-->
  <!--  </div>-->
</div>
