import { Component, OnInit } from '@angular/core';

export interface PhoneChatMessage {
  /**
   * The message text
   */
  message: string;
  /**
   * Whether to show the message as the "user" or "other"
   */
  sent: boolean;
}

@Component({
  selector: 'app-phone-preview',
  templateUrl: './phone-preview.component.html',
  styleUrls: ['./phone-preview.component.scss'],
})
export class PhonePreviewComponent implements OnInit {
  /**
   * The list of messages to display
   */
  messages: PhoneChatMessage[] = [];

  constructor() {
    // Generate 20 random messages
    // for (let i = 0; i < 20; i++) {
    //   this.messages.push({
    //     message: `Message ${i}`,
    //     sent: Math.random() > 0.5,
    //   });
    // }
    // // console.log(this.messages);
    //
    // this.getMessageSidesConversation();
  }

  ngOnInit(): void {}

  getMessageSidesConversation(): PhoneChatMessage[][] {
    // Convert the messages to a list of message sides (i.e. user/other)
    // For example:
    // [
    //   [User Message 1, User Message 2],
    //   [Other Message 1, Other Message 2],
    //   [User Message 3, User Message 4, User Message 5],
    //   [Other Message 3]
    // ]
    const messageSides: PhoneChatMessage[][] = [];
    messageSides.push([]);

    // Loop through the messages

    let lastSide = this.messages[0].sent;
    let sideIndex = 0;

    for (let i = 0; i < this.messages.length; i++) {
      const message = this.messages[i];

      // If the message is from the same side as the last message, add it to the current side
      if (lastSide === message.sent) {
        messageSides[sideIndex].push(message);
      }
      // Otherwise, create a new side
      else {
        sideIndex++;
        messageSides.push([message]);
        lastSide = message.sent;
      }
    }

    // console.log(messageSides);

    return messageSides;
  }

  /**
   * Add a message to the list of messages
   * @param message The message to add
   */
  addMessage(message: PhoneChatMessage) {
    this.messages.push(message);
  }

  /**
   * Clear the list of messages
   */
  clearMessages() {
    this.messages = [];
  }

  setMessage(message: PhoneChatMessage) {
    this.messages = [message];
  }
}
