<div class="smartphone">
  <div class="w-full h-full bg-white">
    <div class="w-full flex flex-col py-2 px-3 overflow-auto max-h-full" *ngIf="messages && messages.length">
      <div class="mt-5 flex flex-col" *ngFor="let msgs of getMessageSidesConversation()" [ngClass]="{ yours: !msgs[0].sent, mine: msgs[0].sent }">
        <div class="message" *ngFor="let msg of msgs; last as isLast" [ngClass]="{ last: isLast }">
          <span [innerHTML]="msg.message"></span>
        </div>
      </div>

      <!--  <div class="yours messages">-->
      <!--    <div class="message">-->
      <!--      Please contact MRS Assoc. regarding a clientName matter at inboundPhone. MRS is a debt collector. If you want to opt-out of future text messages from MRS-->
      <!--      reply STOP.-->
      <!--    </div>-->
      <!--  </div>-->
      <!--  <div class="yours messages">-->
      <!--    <div class="message">Hey!</div>-->
      <!--    <div class="message">You there?</div>-->
      <!--    <div class="message last">Hello, how's it going?</div>-->
      <!--  </div>-->
      <!--  <div class="mine messages">-->
      <!--    <div class="message">Great thanks!</div>-->
      <!--    <div class="message last">How about you?</div>-->
      <!--  </div>-->
    </div>
  </div>
</div>
