import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HotToastService } from "@ngneat/hot-toast";
import { BehaviorSubject, catchError, tap, throwError } from "rxjs";
import { ApiClient, PhoneOptOutDTO } from "../../api/api.service";
import { ModalComponent } from "../../components/modal/modal.component";

@Component({
    selector: 'app-contact-management',
    templateUrl: './account-contact-management-component.html',
    styleUrls: ['./account-contact-management-component.scss'],
  })
  
  export class AccountContactManagementComponent implements OnInit, OnDestroy, AfterViewInit {
    searchPhoneTo: string = '';
    phoneOptOuts: PhoneOptOutDTO[] = [];
    phoneOptOuts$ = new BehaviorSubject<PhoneOptOutDTO[]>([]);
    inPhoneNumber: string = '';
    accountId?: string;

    @ViewChild('addModal') addModal?: ModalComponent;
    
    constructor(private api: ApiClient, private route: ActivatedRoute, private changeDetector: ChangeDetectorRef,private toast: HotToastService){}
  
    ngOnInit(): void {
        this.route.params.subscribe(({ accountId }) => {
            console.log('init' + accountId)
            this.api.listPhoneOptOutsByAccountId({accountId: accountId})
            .subscribe((phoneOptOuts) => {
                this.phoneOptOuts = phoneOptOuts;
                this.phoneOptOuts$.next(phoneOptOuts);
              });
              this.accountId = accountId;
        });
    }
    
    ngAfterViewInit() {
    }
    
    ngOnDestroy(): void {
        this.phoneOptOuts$.unsubscribe();
    }
    
    showAddModal() {
        this.addModal?.show();
    }

    phoneOptOutChanged(event$: any) {
        this.inPhoneNumber = event$;
    }

    removePhoneOptOut() {
        this.inPhoneNumber.replace("+","").replace("(",'').replace(")",'').replace("-",'').replace(" ","").replace(".","");
        this.api.removePhoneOptOutByPhoneNumber(
            {
                phone: this.inPhoneNumber,
                accountId: this.accountId
            })
            .pipe(
            this.toast.observe({
                loading: 'pending...',
                success: (s) => {
                return "complete"
                },
                error: (e) => {
                    return `Failed! ${e.message}`;
                },
            }),
            tap(() => {
            }),
            catchError((err) => {
                this.toast.error(err.message);
                return throwError(err);
            })
            )
            .subscribe((phoneOptOutDTO) => {
                for (let phone of this.phoneOptOuts) 
                {
                    if(phone.id = phoneOptOutDTO.id)
                        this.phoneOptOuts.splice(this.phoneOptOuts.indexOf(phone), 1);
                }
                this.phoneOptOuts$.next(this.phoneOptOuts)
                this.changeDetector.detectChanges();
                this.addModal?.hide();
            });
    }

    addPhoneOptOut() {

        this.inPhoneNumber.replace("+","").replace("(",'').replace(")",'').replace("-",'').replace(" ","").replace(".","");
        this.api.createPhoneOptOutByAccountId(
        {
            phone: this.inPhoneNumber,
            accountId: this.accountId
        })
        .pipe(
        this.toast.observe({
            loading: 'pending...',
            success: (s) => {
            return "complete"
            },
            error: (e) => {
                return `Failed! ${e.message}`;
            },
        }),
        tap(() => {
        }),
        catchError((err) => {
            this.toast.error(err.message);
            return throwError(err);
        })
        )
        .subscribe((phoneOptOutDTO) => {

            phoneOptOutDTO.deletedAt = undefined;
            this.phoneOptOuts?.push(phoneOptOutDTO);
            this.changeDetector.detectChanges();
            
            this.addModal?.hide();
        });
    }

  }