<div class="mb-3">
  <label
    class="block mb-2 text-sm font-medium"
    [ngClass]="{
      'text-gray-900 dark:text-gray-300': !control || control.valid,
      'text-red-700 dark:text-red-500': control?.invalid
    }"
  >
    {{ label }}</label
  >
  <input
    #input
    [type]="inputType"
    class="block w-full p-2.5 rounded-md border text-{{ textSize }} {{ inputClass }}"
    [ngClass]="{
      'text-gray-900 bg-gray-50 border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500':
        !control || control.valid,
      'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400':
        control?.invalid
    }"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (input)="valueChanged($event)"
    [value]="value"
    *ngIf="inputType !== 'textarea'"
  />
  <textarea
    *ngIf="inputType === 'textarea'"
    #tainput
    type="text"
    class="block w-full p-2.5 rounded-md border text-{{ textSize }} {{ inputClass }}"
    [ngClass]="{
      'text-gray-900 bg-gray-50 border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500':
        !control || control.valid,
      'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400':
        control?.invalid
    }"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (input)="valueChanged($event)"
    [value]="value"
    rows="5"
  ></textarea>
  <p *ngIf="control?.invalid" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ validationMessage() }}</p>
  <p *ngIf="control?.errors?.['min']" class="mt-2 text-sm text-red-600 dark:text-red-500">Value must be at least {{control?.errors?.['min'].min}}</p>
  <!--  <pre *ngIf="control?.invalid" class="text-xs text-red-500 dark:text-red-300">-->
  <!--    {{ control!.errors | json }}-->
  <!--  </pre>-->
  <!-- [inputMask]="inputMask" -->
</div>
