<div class="p-5">
  <div class="p-5 flex gap-4 justify-between">
    <div class="bg-white shadow p-5 mb-5 w-1/3">
      <h2 class="text-xl font-bold mb-2">Current Message(s)</h2>

      <ng-container *ngIf="availableConfirmations.length > 0">
        <p>ID: {{ availableConfirmations[0].messageId }}</p>
        <p>Phone: {{ availableConfirmations[0].toPhone }}</p>
        <p>Text: {{ availableConfirmations[0].messageText }}</p>
      </ng-container>
    </div>

    <div class="bg-white shadow p-5 mb-5 w-1/3">
      <h2 class="text-xl font-bold mb-2">Stats 3.2</h2>

      <p>Clicks: {{ clicks }}</p>
      <p>Messages sending: {{ pendingConfirmations.length }}</p>
      <p>Messages available: {{ availableConfirmations.length }}</p>
      <p>User: {{authenticator.user.getUsername() }} </p>
    </div>
  </div>

  <button (click)="confirmedClick()" (keydown)="beforeInput($event)" [disabled]="sending == true" class="clicker-button">
    Confirm Send
  </button>

<br>

    <button (click)="toggleAutoClicker()" class="clicker-button">Toggle Auto Clicker</button>

</div>
