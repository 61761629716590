import { Component, OnInit } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
interface NavLinkTemplate {
  name: string;
  path: string;
  icon: string;
}
@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit {
  links: NavLinkTemplate[] = [
    {
      name: 'Home',
      path: '/',
      icon: 'fa-house-chimney',
    },
    {
      name: 'Account Management',
      path: '/tenant-management',
      icon: 'fa-user-circle',
    },
    {
      name: 'Reports',
      path: '/tenant-management',
      icon: 'fa-user-circle',
    },
    {
      name: 'Message Search',
      path: '/tenant-management',
      icon: 'fa-user-circle',
    },

    {
      name: 'Clicker',
      path: '/clicker',
      icon: 'fa-mouse-pointer',
    },
  ];

  bottomLinks: NavLinkTemplate[] = [
    // {
    //   name: 'Logout',
    //   path: '/logout',
    //   icon: 'fa-sign-out-alt',
    // },
  ];

  constructor(public authenticator: AuthenticatorService) {}

  ngOnInit(): void {}
}
