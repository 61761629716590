import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApiClient, MessageServiceDTO, MessageTemplateDTO, VendorDTO, VendorIntegration, VendorIntegrationDTO } from '../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { PhonePreviewComponent } from '../../components/phone-preview/phone-preview.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { ButtonComponent } from '@aws-amplify/ui-angular';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';

@Component({
  templateUrl: './account-service-management-page.component.html',
  styleUrls: ['./account-service-management-page.component.scss'],
})

export class AccountServiceManagementPageComponent implements OnInit {
  @ViewChild('phonePreview') phonePreview?: PhonePreviewComponent;
  @ViewChild('previewModal') previewModal?: ModalComponent;
  @ViewChild('integrationModal') integrationModal?: ModalComponent;
  service: MessageServiceDTO = {};

  public integrations: VendorIntegrationDTO[] = [];
  public integrations$ = new BehaviorSubject<VendorIntegrationDTO[]>([]);

  templates: MessageTemplateDTO[] = [];
  hasIntegration: boolean = false;
  addingIntegration: boolean = false;
  vendorIntegrationName: string = '';
  vendorId: string = '';

  public vendors?: VendorDTO[];
  vendors$ = new BehaviorSubject<VendorDTO[]>([]);
  vendor: VendorDTO = {};

  vendorAccountId: string = '';
  vendorServiceId: string = '';
  updatedDate: Date = new Date();

  private accountId?: string;
  private serviceId?: string;
  constructor(private api: ApiClient, private route: ActivatedRoute, private changeDetector: ChangeDetectorRef, private toast: HotToastService) {
    // /accounts/:accountId/services/:serviceId - accountId and serviceId are required
    // Get the full service info from the API
    this.hasIntegration = false;
    this.route.params.subscribe(({ accountId, serviceId }) => {
      this.accountId = accountId;
      this.serviceId = serviceId;
      this.api
        .getService(accountId, serviceId)
        .pipe(
          tap((service) => {
            this.service = service;
            this.integrations = service.vendorIntegrations ?? [];
            this.templates = service.messageTemplates ?? [];
            this.changeDetector.detectChanges();
          }),
          switchMap(() => this.api.listVendorIntegrations(accountId, serviceId)),
          tap((integrations) => {
            this.integrations = integrations;
            this.integrations$.next(integrations);
            console.log(integrations.length);
            if(integrations.length > 0)
            {
              this.hasIntegration = true;
            }
            this.changeDetector.detectChanges();
          }),

          switchMap(() => this.api.listMessageTemplates(accountId, serviceId)),
          tap((templates) => {
            this.templates = templates;
            this.changeDetector.detectChanges();
          })
        )
        .subscribe();
    });
  }

  ngOnInit(): void {}

  getTemplateHtml(template: MessageTemplateDTO) {
    return template.template?.replace(/\n/g, '<br>');
  }

  testRender(t: MessageTemplateDTO) {
    this.api
      .renderRawMessageTemplate(this.accountId!, this.serviceId!, t.id!, {
        template: t.template,
        model: t.parameters
      })
      .pipe(
        tap(({ rendered }) => {
          // console.log(rendered);
          // this.toast.success(rendered, {
          //   position: 'bottom-center',
          //   duration: 5000,
          // });

          this.previewModal?.show();
          this.phonePreview?.clearMessages();
          this.phonePreview?.addMessage({
            sent: false,
            message: rendered ?? 'TEMPLATE RENDER ERROR',
          });
        })
      )
      .subscribe();
  }

  addIntegration(){
    this.api.listVendors()
    .subscribe((vendors) => {
      this.vendors = vendors;
      this.vendors$.next(vendors);
    });

    this.integrationModal?.show();
    this.addingIntegration = true;
  }

  addThisIntegration() {

    //TODO this api calls need to return the actual new integration
    this.api.createVendorIntegration(
      {  
        vendorId: this.vendor.id,
        messageServiceId: this.serviceId,
        vendorAccountId: this.vendorAccountId,
        vendorServiceId: this.vendorServiceId,
        enabled: true,
        updatedAt: this.updatedDate
       }).subscribe((integration) => {
         this.hasIntegration = true;
        this.integrations?.push(integration);
        this.integrations$.next(this.integrations);
        this.changeDetector.detectChanges();
        this.integrationModal?.hide();
        this.vendorAccountId = '';
        this.vendorServiceId = '';
        this.addingIntegration = false;
      });
        
    }


  

  vendorChanged($event: any) {
    this.vendor = $event;
  }

  vendorAccountIdChanged($event: any){
    this.vendorAccountId = $event;
  }

  vendorServiceIdChanged($event: any){
    this.vendorServiceId = $event;
  }

  ngOnDestroy(){
      this.vendors$.unsubscribe();
      this.integrations$.unsubscribe();
  }
  
}
