import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var Modal: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal!: ElementRef;
  @Input() backdropClasses: string = 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40';
  private _modal: any;
  @Input() title?: string;
  @Input() closeOnBackdropClick = true;

  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';

  constructor(private el: ElementRef) {}

  get sizeClass() {
    switch (this.size) {
      case 'sm':
        return 'max-w-md';
      case 'md':
        return 'max-w-lg';
      case 'lg':
        return 'max-w-4xl';
      case 'xl':
        return 'max-w-7xl';
    }
  }

  ngAfterViewInit(): void {
    console.log(this.modal);
    console.log(this.el);
    this._modal = new Modal(this.modal.nativeElement, {
      backdropClasses: this.backdropClasses,
      placement: 'center',
      onHide: () => {
        this.onHide();
      },
      onShow: () => {
        this.onShow();
      },
      onToggle: () => {
        this.onToggle();
      },
    });
  }

  ngOnInit(): void {}

  toggle() {
    this._modal.toggle();
  }

  show() {
    this._modal.show();
  }

  hide() {
    this._modal.hide();
  }

  private onHide() {}

  private onShow() {}

  private onToggle() {}

  backdropClicked($event: MouseEvent) {
    if ($event.target === this.modal.nativeElement && this.closeOnBackdropClick) {
      this.hide();
    }
  }
}
