<div class="flex items-start justify-between">
  <div class="flex flex-col w-full md:space-y-4 pt-4">
    <div class="overflow-auto h-screen pb-24 px-4 md:px-6">
      <div class="bg-white p-5">
        <h1>Accounts</h1>
        <p>Below is the listing of all available accounts in the Hermes application. Click the ID to manage a given account.</p>
        <p>TODO: Add search</p>

        <div class="relative overflow-x-auto mt-6">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">Id</th>
                <th scope="col" class="px-6 py-3">Name</th>
                <th scope="col" class="px-6 py-3">Enabled</th>
                <th scope="col" class="px-6 py-3">Created</th>
                <th scope="col" class="px-6 py-3">Test</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" *ngFor="let t of tenants$ | async">
                <td class="px-6 py-4">
                  <a [routerLink]="[t.id]" class="text-blue-600 dark:text-blue-500 hover:underline" style="font-family: monospace">{{ t.id }}</a>
                </td>
                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ t.name }}</td>
                <td class="px-6 py-4">{{ t.enabled }}</td>
                <td class="px-6 py-4">{{ t.createdAt | date }}</td>
                <td class="px-6 pt-4 pb-2">
                  <app-button variant="outline" (buttonClick)="beginSendSmsModal(t)">Send SMS</app-button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="my-7 w-full flex justify-end">
            <app-button type="button" (buttonClick)="showAccountModal()" buttonText="Add Account"></app-button>
          </div>
        </div>
      </div>

      <app-modal #addAccountModal size="lg">
        <h1>Add Account</h1>

        <form [formGroup]="newAccountForm">
          <div class="grid">
            <app-input formControlName="accountName" label="Account Name" inputType="text" required></app-input>
            <app-input formControlName="company" label="Company" inputType="text" required></app-input>
          </div>

          <div class="grid grid-cols-3 gap-4">
            <app-input formControlName="contactName" label="Contact Name" inputType="text" required></app-input>
            <app-input formControlName="contactEmail" label="Email" inputType="text" required></app-input>
            <app-input formControlName="contactPhone" label="Phone" inputType="tel" required></app-input>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <app-input formControlName="address1" label="Address1" inputType="text" required></app-input>

            <app-input formControlName="address2" label="Address2" inputType="text"></app-input>
          </div>

          <div class="grid grid-cols-3 gap-4">
            <app-input formControlName="city" label="City" inputType="text" required></app-input>
            <app-input formControlName="state" label="State" inputType="text" required></app-input>
            <app-input formControlName="zip" label="Zip" inputType="text" required></app-input>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <app-input
              formControlName="customerValidationHook"
              label="Customer Validation Hook"
              pattern="https://.*"
              placeholder="https://example.com/check"
              inputType="url"
            ></app-input>
          </div>

          <div class="grid gap-4">
            <select formControlName="vendor" id="vendor">
              <option *ngFor="let vendor of vendors$ | async" [value]="vendor.name">{{ vendor.name }}</option>
            </select>

            <app-button buttonClass="mt-6" buttonType="submit" (buttonClick)="addAccount()" buttonText="Add Account"></app-button>
          </div>
        </form>
      </app-modal>

      <app-modal #sendSmsModal>
        <h3>Queue SMS to {{ sendToTenant?.name }}</h3>
        <form [formGroup]="sendSmsForm">
          <app-input formControlName="toPhone" label="To Phone Number" inputType="tel"></app-input>
          <app-input formControlName="message" label="Message" inputType="text"></app-input>

          <!-- Show a list of message service options for the user to choose from -->
          <!--    6d91bd67-6be8-4fea-986c-1beff284eb0d-->
          <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select message service</label>
          <select
            formControlName="messageService"
            id="countries"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option *ngFor="let service of tenantServices" [value]="service.id">{{ service.name }}</option>
          </select>

          <div class="flex items-center mb-4">
            <input
              #requireClickerCheck
              formControlName="requireManual"
              aria-describedby="checkbox-1"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              checked
            />
            <label [for]="requireClickerCheck" class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Require Clicker</label>
          </div>

          <app-button buttonClass="mt-6" buttonType="submit" (buttonClick)="sendSms()">Send</app-button>
        </form>
      </app-modal>
    </div>
  </div>
</div>
